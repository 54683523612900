import React from "react";
import './header.scss'
import F1HeaderNav from 'f1-header-navigation-react';
import logo from '../../assets/img/GH-logo-research-advisors-white.svg'
import AuthContext from "../../routes/authContext";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: 1,
    };
  }
  logout = () => {
    this.context.onLogout()
    this.props.history.push("/login");
  }

  logoClick = () => {
    this.props.history.push("/home");
  }

  CustomBrand = ({ }) => <div className="my-custom-brand">
  </div>

  onRedirect = () => {
    this.props.history.push("/dashboard")
  }
  onItemClick = (item, child_item) => {

    if (child_item && child_item.id === "logout") {

      this.logout()
      this.props.history.push('/login')

    } else if (child_item && child_item.id === "changePassword") {
      this.props.history.push('/changePassword')
    } else if (child_item && child_item.id === "editProfile") {
      this.props.history.push('/editProfile')
    }
    else if (child_item && child_item.id === "userManagement") {
      this.props.history.push('/userManagement')
    }
    else if (child_item && child_item.id === "entityManagement") {
      this.props.history.push('/entitymanagement')
    }
    else if (item.id === "Alert") {
      this.props.history.push('/emailalert')
    } else if (item.id === "dashboardLink") {
      this.props.history.push('/dashboard')
    } else if (item.id === "mail") {

      window.open('mailto:jets@gordonhaskett.com?subject=Add%20Entity&body=I%20am%20a%20subscriber%20to%20the%20Gordon%20Haskett%20Jets%20service.%0D%0A%0D%0AIf%20the%20following%20entity%20has%20a%20corporate%20aircraft%2C%20I%20would%20like%20it%20added%20to%20the%20database.%0D%0A%0D%0AEntity%20Name%3A%20%0D%0ATicker%3A%20%0D%0A%0D%0AThank%20you%2C%0D%0AName%3A%0D%0ACompany%3A')
    }
    else if (child_item && child_item.id === "email") {

    }
    else {
      this.logout()
      this.props.history.push('/login')
    }


  }

  brandImgStyle = { width: '90px' }
  navBarStyle = { backgroundColor: '#225cb2' }
  navBarSimpleStyle = { backgroundColor: '#A9A9A9' }
  headerAdminConfig = {
    navBarStyle: this.navBarStyle,
    navBarClassName: 'normal-header',
    brandType: 'text', // text/image/component, required
    brandText: <span className="branding" onClick={this.onRedirect}>
      <img className="mainLogo" src={logo} style={{ width: '250px', margin: "13px 20px" }} />
    </span>,
    brandLink: '',
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    brandStyle: this.brandImgStyle, // style to be apply on brand component
    disableHambergerIcon: true, // default true
    onItemClick: this.onItemClick,
    menus: {


      right: [
        {
          type: 'link', // link/dropdown
          label: 'Dashboard',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'dashboardLink',
          id: "dashboardLink"
        },
        {

          type: 'link', // link/dropdown
          label: 'Add Jet to Database',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'mail',
          id: "mail"
        },
        {
          type: 'link', // link/dropdown
          label: 'Setup Watchlist',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'Alert',
          id: "Alert"
        },

        {
          type: 'dropdown', // link/dropdown
          label: '',
          icon: 'user',
          className: 'custom-dropdown',
          childs: [
            {
              label: ' ' + localStorage.getItem("email"),
              icon: 'user',
              id: "email"
            },
            {
              label: ' Change Password',
              href: '',
              className: 'custom-option',
              icon: 'lock',
              id: "changePassword"
            },
            {
              type: 'link', // link/dropdown
              label: 'User Management',
              icon: 'user-plus',
              href: '',
              // in case of dropdowns
              is_active: false,
              //className: localStorage.getItem('role')=="Administrator"?'userManagement displayUserManagement':"userManagement hideUserManagement",
              id: "userManagement"
            },
            {
              type: 'link', // link/dropdown
              label: 'Ownership Management',
              icon: 'cog',
              href: '',
              // in case of dropdowns
              is_active: false,
              //className: localStorage.getItem('role')=="Administrator"?'userManagement displayUserManagement':"userManagement hideUserManagement",
              id: "entityManagement"
            },
            {
              label: ' Logout',
              href: '',
              className: 'custom-option',
              icon: 'sign-out',
              id: 'logout'
            }
          ], // in case of dropdowns
          is_active: false,
        },

      ],

    }
  }
  headerConfig = {
    navBarStyle: this.navBarStyle,
    navBarClassName: 'normal-header',
    brandType: 'text', // text/image/component, required
    brandText: <span className="branding" onClick={this.onRedirect}>
      <img className="mainLogo" src={logo} style={{ width: '250px' }} />
    </span>,
    brandLink: '',
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    brandStyle: this.brandImgStyle, // style to be apply on brand component
    disableHambergerIcon: true, // default true
    onItemClick: this.onItemClick,
    menus: {


      right: [
        {
          type: 'link', // link/dropdown
          label: 'Dashboard',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'dashboardLink',
          id: "dashboardLink"
        },
        {

          type: 'link', // link/dropdown
          label: 'Add Jet to Database',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'mail',
          id: "mail"
        },
        {
          type: 'link', // link/dropdown
          label: 'Setup Watchlist',
          icon: '',
          href: '',
          childs: [], // in case of dropdowns
          is_active: true,
          className: 'Alert',
          id: "Alert"
        },

        {
          type: 'dropdown', // link/dropdown
          label: '',
          icon: 'user',
          className: 'custom-dropdown',
          childs: [
            {
              label: ' ' + localStorage.getItem("email"),
              href: '',
              className: 'custom-option',
              icon: 'user',
              id: "email"
            },
            {
              label: ' Change Password',
              href: '',
              className: 'custom-option',
              icon: 'lock',
              id: "changePassword"
            },
            {
              label: ' Logout',
              href: '',
              className: 'custom-option',
              icon: 'sign-out',
              id: 'logout'
            }
          ], // in case of dropdowns
          is_active: false,
        },

      ],

    }
  }
  render() {
    return (

      <div>
        <F1HeaderNav
          config={localStorage.getItem('role') == "Administrator" ? this.headerAdminConfig : this.headerConfig}
          brandComponent={<this.CustomBrand />} // in case of type === component
        />
      </div>
    );
  }
}

Header.contextType = AuthContext
export default Header;
