import React from 'react';
import './entityManagement.scss';
import Header from '../header/header';
import DynamicForm from 'f1-dynamic-form-react';
import Footer from '../footer/footer';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles'
import { createTheme } from '@mui/material';
import { get, post, deleteData, put } from '../../api/api';
import { GET_OWNERSHIP } from '../../api/baseURL';
import ModalPopup from 'f1-modal-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

class EntityManagement extends React.Component {
  constructor(props) {
    super(props)
    this.dynamicFormRef = React.createRef();
    this.state = {
      current: {
        createdDate: "2015-01-01"
      },
      modalDeleteShow: false,
      users: [],
      modalShow: false,
      loader: true,
      deleteData: "",
      flow: "",
      showMsg: false,
      severity: "",
      message: "",
      formData: [
        {
          "key": "companyName",
          "disabled": false,
          "id": "companyName",
          "icon": "factory",
          "label": "Company Name",
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "ticker",
          "disabled": false,
          "id": "ticker",
          "icon": "destination",
          "label": "Ticker"

        },
        {
          "key": "make",
          "disabled": false,
          "id": "make",
          "icon": "make",
          "label": "Make",
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "model",
          "icon": "model",
          "label": "Model",
          "hide": false,
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "planeId",
          "disabled": false,
          "id": "planeId",
          "icon": "plane",
          "label": "Plane Id",
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "ownershipType",
          "disabled": false,
          "type": "select",
          "id": "ownershipType",
          "icon": "owner",
          "label": "Ownership Type",
          "options": [
            { "key": "Select", "label": "Select Type", "value": "" },
            { "key": "full", "label": "Full", "value": "Full" },
            { "key": "fractional", "label": "Fractional", "value": "Fractional" },
            { "key": "coOwned", "label": "Co-Owned", "value": "Co-Owned" }
          ],
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "tailNumber",
          "disabled": false,
          "id": "tailNumber",
          "icon": "tail",
          "label": "Tail Number",
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "jetnetId",
          "disabled": false,
          "id": "jetnetId",
          "icon": "jet",
          "label": "Jetnet Id",
          "validators": [
            { "name": "required", "value": true }
          ]

        },
        {
          "key": "createdDate",
          "label": "Created Date",
          "type": "date",
          "id": "createdDate",
          "max": "9999-12-31",
          "noDate": null,
          "validators": [
            { "name": "required", "value": true }
          ]

        },


        {
          "key": "endDate",
          "label": "End Date",
          "type": "date",
          "id": "endDate",
          "max": "9999-12-31",
          "noDate": null

        },
        {
          "key": "jetnetContactId",
          "disabled": false,
          "id": "jetnetContactId",
          "icon": "jet",
          "label": "Jetnet Contact Id"

        },
        {
          "key": "jetnetParentId",
          "disabled": false,
          "id": "jetnetParentId",
          "icon": "jet",
          "label": "Jetnet Parent Id"

        }
      ]
    }
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.getUsersData()
  }

  getUsersData = () => {
    get(GET_OWNERSHIP).then(res => {
      this.setState({ users: res.data, loader: false })
    }).catch(error => {
      console.log(error)
    })
  }

  onSignup = (model) => {
    // you can set error messages at any event for example when form submitted
    // you can general key to show general error messsage for whole form for e.g. "Something went wrong!"
    // for showing error messages for a field user field key as show below
    let companyName = encodeURIComponent(model.companyName);
    if (Object.keys(model.errors).length == 0) {
      if (this.state.flow == "Edit") {
        let defaultCreatedDate = model.createdDate ? model.createdDate : new Date("January 01, 2015 11:11:00");
        let defaultEndDate = model.endDate ? model.endDate : '';
        let defaultjetnetParentId = model.jetnetParentId ? model.jetnetParentId : '';
        let defaultjetnetContactId = model.jetnetContactId ? model.jetnetContactId : '';
        let url = 'p_ownershipid=' + model.ownershipId +
          '&p_make=' + model.make +
          '&p_model=' + model.model +
          '&p_planeid=' + model.planeId +
          '&p_ownershiptype=' + model.ownershipType +
          '&p_companyname=' + companyName +
          '&p_jetnetid=' + model.jetnetId +
          '&p_tailnumber=' + model.tailNumber +
          '&p_ticker=' + model.ticker +
          '&p_createddate=' + defaultCreatedDate +
          '&p_enddate=' + defaultEndDate +
          '&p_jetnetparentid=' + defaultjetnetParentId +
          '&p_jetnetcontactid=' + defaultjetnetContactId;
        put(GET_OWNERSHIP + url).then(res => {
          this.setState({ flow: "", current: {}, modalShow: false, severity: "success", message: "Record Updated", showMsg: true })
          this.getUsersData()

        }).catch(error => {
          this.setState(({ flow: "", current: {}, modalShow: false, severity: "error", message: "Record Fail To Update", showMsg: true }))
          console.log(error)
        })
      } else {
        let defaultCreatedDate = model.createdDate ? model.createdDate : new Date("January 01, 2015 11:11:00");
        let defaultEndDate = model.endDate ? model.endDate : '';
        let defaultjetnetParentId = model.jetnetParentId ? model.jetnetParentId : '';
        let defaultjetnetContactId = model.jetnetContactId ? model.jetnetContactId : '';
        let url = 'p_make=' + model.make +
          '&p_model=' + model.model +
          '&p_planeid=' + model.planeId +
          '&p_ownershiptype=' + model.ownershipType +
          '&p_companyname=' + companyName +
          '&p_jetnetid=' + model.jetnetId +
          '&p_tailnumber=' + model.tailNumber +
          '&p_ticker=' + model.ticker +
          '&p_createddate=' + defaultCreatedDate +
          '&p_enddate=' + defaultEndDate +
          '&p_jetnetparentid=' + defaultjetnetParentId +
          '&p_jetnetcontactid=' + defaultjetnetContactId;

        post(GET_OWNERSHIP + url).then(res => {
          this.setState({ flow: "", current: {}, modalShow: false, severity: "success", message: "Record Added", showMsg: true })
          this.getUsersData()

        }).catch(error => {
          this.setState(({ flow: "", current: {}, modalShow: false, severity: "error", message: "Record Fail To Add", showMsg: true }))
          console.log(error)
        })
      }
    } else {

    }

    //this.setState({flow:"",current:{},modalShow:false})
  };

  onAdd = (e) => {
    let current = {
      make: "",
      model: "",
      planeId: "",
      ownershipType: "",
      companyName: "",
      jetnetParentId: "",
      jetnetContactId: "",
      jetnetId: "",
      tailNumber: "",
      ticker: "",
      createdDate: moment("2015-01-01").format('YYYY-MM-DD'),
      endDate: ""
    }

    this.setState({ flow: "Add", current: current, modalShow: true })

  }

  onEdit = (props) => {
    let current = {
      ownershipId: props.ownershipId,
      make: props.make,
      model: props.model,
      planeId: props.planeid,
      ownershipType: props.ownershiptype,
      companyName: props.companyname,
      jetnetParentId: props.jetnetparentid,
      jetnetContactId: props.jetnetcontactid,
      jetnetId: props.jetnetid,
      tailNumber: props.tailnumber,
      ticker: props.ticker,
      // createdDate:"2021-01-27",
      createdDate: moment.utc(props.createdDate).format('YYYY-MM-DD'),
      endDate: props.endDate ? moment.utc(props.endDate).format('YYYY-MM-DD') : ""
    }
    this.setState({ flow: "Edit", current: current, modalShow: true })
  }

  onEnter = () => {

  }

  onExit = (e) => {

  }

  onExits = (e) => {
    this.setState({ modalShow: false })
  }

  deleteConfirmation = (props) => {
    this.setState({ modalDeleteShow: true, deleteData: props })
  }

  onDelete = () => {
    let url = 'p_ownershipid=' + this.state.deleteData.ownershipId;

    deleteData(GET_OWNERSHIP + url).then(res => {
      // alert("done")
      this.setState({ message: "Record Deleted", showMsg: true, severity: 'success', modalDeleteShow: false })
      this.getUsersData()
    }).catch(error => {
      console.log(error)
      this.setState({ message: "Record Fail to Delete", showMsg: true, severity: 'error', modalDeleteShow: false })
    })
    // this.setState({modalDeleteShow:false})
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showMsg: false })
  };


  render() {
    const mytheme = createTheme({
    });

    return (
      <>
        <div className="main-container entityManagement userGrid">
          <Header history={this.props.history} />
          <div className="analytics-section">
            <Paper style={{ textAlign: "left" }}>
              <h4 className="pageTitle" style={{ padding: "0 15px" }}>Ownership Management  <button onClick={this.onAdd} className="addEntity">Add Ownership</button></h4>
              <Snackbar open={this.state.showMsg} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })} autoHideDuration={1000}><Alert severity={this.state.severity} >{this.state.message}</Alert></Snackbar>
              {this.state.loader ?
                <CircularProgress color="secondary" className="loader" /> : ""}

              <ThemeProvider theme={mytheme}>
                <MaterialTable
                  inputRef={this.tableInputRef}
                  data={this.state.users && this.state.users.length > 1 ? this.state.users : []}
                  columns={
                    [
                      { title: 'OwnershipId', field: 'ownershipId', hidden: true },
                      { title: 'Make', field: 'make' },
                      { title: 'Model', field: 'model', sorting: false },
                      // sorting false above
                      { title: 'Plane Id', field: 'planeid', sorting: true },
                      { title: 'Ownership Type', field: 'ownershiptype', sorting: false },
                      { title: 'Company Name', field: 'companyname', sorting: false },
                      { title: 'Jet Net Id', field: 'jetnetid', sorting: false },
                      { title: 'Tail Number', field: 'tailnumber', sorting: false },
                      { title: 'Ticker', field: 'ticker', sorting: false },
                      {
                        title: 'Created Date', field: 'createdDate', sorting: false, render: (props) => {
                          return moment.utc(props.createdDate).format('MM-DD-YYYY')
                        }
                      },

                      {
                        title: 'End Date', field: 'endDate', sorting: false,
                        render: (props) => {
                          return props.endDate ? moment(props.endDate).format('MM-DD-YYYY') : ""
                        }
                      },
                      {
                        title: 'Actions', field: '', render: (props) => {
                          return <div><span className="showLink" onClick={() => this.onEdit(props)}>Edit</span> | <span className="showLink" onClick={() => this.deleteConfirmation(props)}>Delete</span></div>
                        }
                      }
                    ]
                  }
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No data to display",
                      searchPlaceholder: "Search" // Add the searchPlaceholder property here
                    },
                    toolbar: {
                      searchPlaceholder: 'Search Here',
                      searchTooltip: 'Search'
                    }
                  }}
                  options={{
                    showTitle: false,
                    maxBodyHeight: '52.4vh',
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100, 200],
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      color: 'black'
                    }
                  }}
                />
              </ThemeProvider>
              <ModalPopup
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false })}
                size={"lg"}                                       // "lg","sm" or "xl"
                centered={true}                                   // vertically center the Dialog in the window
                modelHeader={this.state.flow === "Edit" ? "Edit Ownership" : "Add Ownership"}                     // to show model header
                modelContent={<DynamicForm
                  onRef={ref => this.dynamicFormRef = ref}
                  key={this.state.current.id}
                  className="form horizontal entityForm"
                  title=" "
                  buttonSubmit="Submit"
                  defaultValues={this.state.current}
                  model={this.state.formData}
                  onSubmit={(model) => {
                    this.onSignup(model);
                  }}
                  disableSubmitOnInValid={false}
                  customValidators={this.customValidators}
                  displayInformation={true}
                  // displaydetails="Please fill all required fields."
                  design="bootstrap" // material
                  //extErrors={this.state.extErrors} // pass external error messages here if any
                  onChange={this.onChangeHandler}
                  // onAutocompleteSelect={this.onAutocompleteSelect}
                  // autoCompleteItems={this.autoCompleteItems}
                  buttonNormalClick={(id, key) => console.log(id, key)}
                />}   // modelFooter={<button onClick={this.onclick}>Close</button>}             //// to show model footer
                // modelFooter={<></>}
                backdrop={'static'}                               // true, false or 'static'
                scrollable={true}                                 //boolean
                onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
                onExit={this.onExits}                              //Callback fired right before the Modal transitions out
                closeButtonShow={true}
                parentClassName="addEditUser"
                headerClassName="ModalHeaderBackground"
                bodyClassName="ModalBodyBackground editUser"
              />
            </Paper>
          </div>
        </div>
        <Footer />
        <ModalPopup
          id={"delteUserPopup"}
          show={this.state.modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          size={"sm"}                                       // "lg","sm" or "xl"
          centered={true}                                   // vertically center the Dialog in the window
          modelHeader={"Delete Ownership"}                     // to show model header
          modelContent={<p>Are you sure you want to delete this ownership?</p>}             // to show model content
          modelFooter={<>
            <Button onClick={this.onDelete}>YES</Button>
            <Button onClick={() => this.setState({ modalDeleteShow: false })}>NO</Button>
          </>}             //// to show model footer
          backdrop={'static'}                               // true, false or 'static'
          scrollable={true}                                 //boolean
          onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
          onExit={this.onExit}                              //Callback fired right before the Modal transitions out
          closeButtonShow={true}
          parentClassName="ModalBackground"
          headerClassName="ModalHeaderBackground"
          bodyClassName="ModalBodyBackground"
          footerClassName="ModalFooterBackground"
        />
      </>
    )
  }
}

export default EntityManagement;
