import CryptoJS from 'crypto-js';
// Function to encrypt password before sending to server
export function encryptData(data, secretKey) {

    // Generate a salt with random word array in 16 bytes
    var salt = CryptoJS.lib.WordArray.random(128 / 8);

    // Derive a 8 bytes key from salt and key stored in process variable using PBKDF2 algorithm with 100 iterations
    var key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: 256 / 32,
        iterations: 100
    });

    // Generate a random 16 bytes Initialiazation Vector (IV)
    var iv = CryptoJS.lib.WordArray.random(128 / 8);

    // Encrypt the data with derived key, generated iv, paddding and mode
    var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // Concatenate salt, IV & encrypted data
    var encryptedPassword = salt.toString() + iv.toString() + encrypted.toString();
    return encryptedPassword;
}

// Function to decrypt encrypted passoword
export function decryptData(encryptedPassword, secretKey) {

    // Separate salt, iv and encrypted from given encryptedPassword
    var salt = CryptoJS.enc.Hex.parse(encryptedPassword.substr(0, 32));
    var iv = CryptoJS.enc.Hex.parse(encryptedPassword.substr(32, 32));
    var encrypted = encryptedPassword.substring(64);

    var key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: 256 / 32,
        iterations: 100
    });

    // Decrypt the password
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}
