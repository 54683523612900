import React from "react";
import './contactUs.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Footer from "../footer/footer";
import { Link } from 'react-router-dom';
import DynamicForm from 'f1-dynamic-form-react';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { CUSTOM_EMAIL_NOTIFICATION } from '../../api/baseURL';
import { post } from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { contactUsEmailSubject, contactUsEmailTemplate, adminEmail } from "../emailSchema/emailSchema";

class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      current: {},
      FormJson: [
        { "key": "firstName", "disabled": false, "id": "firstName", "icon": "user", "label": "First Name", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 charators allowed." }] },
        { "key": "lastName", "disabled": false, "id": "lastName", "icon": "user", "label": "Last Name", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 charators allowed." }] },
        { "key": "email", "icon": "envelope", "label": "Email Address", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 5 }, { "name": "isEmail", "value": true }] },

        // { "key": "name", "disabled": false, "id": "name", "icon": "user", "label": "Name", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 charators allowed." }] },
        { "key": "companyname", "disabled": false, "id": "companyname", "icon": "user", "label": "Company", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 40, "validationMessage": "Maximum 40 charactors allowed." }] },

        { "key": "phone", "type": "tel", "icon": "envelope", "label": "Telephone", "validators": [{ "name": "required", "value": true }] },

        {
          "key": "message",
          "label": "Your Message",
          "type": "textarea",
          "rows": "5",
          "validators": [{ "name": "required", "value": true }]
        },
      ]
    }
  }
  onSelect = (k) => {
    this.setState({ key: k })
  }

  onSignup = (model) => {
    let emailSchema = {
      "listEmailReceivers": [
        {
          "receiverName": model.firstName,
          "receiverEmail": adminEmail
        }
      ],
      "emailSubject": contactUsEmailSubject,
      "emailTemplate": contactUsEmailTemplate,
      "emailBodyVariables": [
        {
          "FirstName": model.firstName,
          "LastName": model.lastName,
          "CompanyName": model.companyname,
          "EmailId": model.email,
          "Telephone": model.phone,
          "MessageRecieved": model.message
        }
      ]
    }

    post(CUSTOM_EMAIL_NOTIFICATION, emailSchema).then(res => {
      this.setState({ message: "Thank you for contacting us!", show: true })
    }).catch(err => {
      this.setState({ message: "Opps,this site can't be reached.", show: true })
    })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false })
  };

  render() {
    return (
      <>
        <div className="main-container contactUs">
          <video id="myVideo" loop muted autoPlay playsInline>
            <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
          </video>
          <Header history={this.props.history} />
          <Grid container style={{ textAlign: "left" }} className={"whiteBox"}>
            <Grid item sm={12} md={12} className="" >
              <Snackbar open={this.state.show} autoHideDuration={9000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="success" >Thank you for contacting us!</Alert></Snackbar>
              <div className="contactUsPaper ">
                <span className="cross"><Link to="/"><CloseIcon /></Link></span>
                <Grid container className={"whiteBoxInner"}>
                  <Grid item sm={3} md={3} >
                    <div style={{ padding: "20px" }}>
                      <h3>Contact us</h3>
                      <h6>Offices</h6>
                      <p><b>New York, NY</b>
                        <div>441 Lexington Avenue<br />
                          New York, NY 10017<br />
                          +1 212 883 0600<br />
                          jets@gordonhaskett.com
                        </div>
                      </p>
                    </div>
                  </Grid>
                  <Grid item sm={8} md={8} >
                    <DynamicForm
                      key={this.state.current.id}
                      className="form"
                      title=" "
                      buttonSubmit="send message"
                      defaultValues={this.state.current}
                      model={this.state.FormJson}
                      onSubmit={(model) => {
                        this.onSignup(model);
                      }}
                      disableSubmitOnInValid={true}
                      customValidators={this.customValidators}
                      displayInformation={true}
                      displaydetails="Please use this form to send a message to Gordon Haskett general inquiries mailbox."
                      design="bootstrap" // material
                      extErrors={this.state.extErrors} // pass external error messages here if any
                      onChange={this.onChangeHandler}
                      onAutocompleteSelect={this.onAutocompleteSelect}
                      autoCompleteItems={this.autoCompleteItems}
                    />
                  </Grid>

                </Grid>
              </div>
              {/* <p className="copyRight">Copyright © 2021 | All Rights Reserved. </p> */}
            </Grid>
          </Grid>

        </div>
        <Footer linkSection="" />
      </>

    );
  }
}

export default ContactUs;
