import React from "react";
import './register.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { post } from '../../api/api';
import { CREATE_REQUEST } from '../../api/baseURL';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Footer from '../footer/footer'
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      showMsg: false,
      message: "",
      severity: "",
      loading: false,
      current: { id: 1 },
      formValues: {
        email: '',
        firstName: '',
        lastName: '',
        companyOrOrganization: '',
        phone: ''
      },
      formErrors: {
        email: '',
        firstName: '',
        lastName: '',
        companyOrOrganization: '',
        phone: ''
      },
      formValidity: {
        email: false,
        firstName: false,
        lastName: false,
        companyOrOrganization: false,
        phone: false
      }
    };
  }

  componentDidMount() {
  }

  onChangeHandler = (event, key) => {
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showMsg: false })
  };

  renderFieldsetAccordianLabel = (collapseId) => {
    const buttonStyle = {
      float: 'right',
      fontweight: '800',
      fontSize: '25px',
      padding: '5px 20px 5px 20px',
      backgroundColor: 'green',
      cursor: 'pointer',
      color: '#fff'
    }
    return (
      <div classNameName="collapse-accordian-custom">
        <span style={buttonStyle} onClick={(e) => this.toggleFieldsetAccordian(collapseId)}>+</span>
        <strong>This is custom rendered label</strong>
      </div>
    );
  }

  submitForm = (event, value) => {
    event.preventDefault();
    const {
      email: { value: email },
      firstName: { value: firstName },
      lastName: { value: lastName },
      companyOrOrganization: { value: companyOrOrganization },
      phone: { value: phone }
    } = event.target.elements;

    let requestSchema = {
      "email": email.toLowerCase(),
      "firstName": firstName,
      "lastName": lastName,
      "companyOrOrganization": companyOrOrganization,
      "phone": phone
    }
    this.setState({ loading: true })
    post(CREATE_REQUEST, requestSchema).then(res => {
      this.setState({ message: "Request sent for approval", showMsg: true, severity: "success", loading: false })
      setTimeout(() => {
        this.props.history.push("/")
      }, 1000)

    }).catch(err => {
      if (err.status == 400) {
        this.setState({ message: "Email already in use", showMsg: true, severity: "error", loading: false })
      } else {
        this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: "error", loading: false })
      }
    })
  }

  handleValidation = (target) => {
    const { name, value } = target;
    const fieldValidationErrors = this.state.formErrors;
    const validity = this.state.formValidity;

    //check input type
    const isEmail = name === "email"
    //check input format
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //check input length must not be empty
    validity[name] = value.length > 0;

    fieldValidationErrors[name] = validity[name]
      ? ""
      : `Field is required and cannot be empty`;
    if (validity[name]) {
      if (isEmail) {
        validity[name] = emailTest.test(value);
        fieldValidationErrors[name] = validity[name] ? "" : `Please enter a valid ${name}`;
      }
      this.setState({
        formErrors: fieldValidationErrors,
        formValidity: validity
      })

    }
  };

  handleChange = ({ target }) => {
    const { formValues } = this.state;
    formValues[target.name] = target.value;
    this.setState({ formValues });
    this.handleValidation(target)
  }

  render() {
    const { formValidity, formErrors, formValues, showMsg, message, severity } = this.state;
    return (
      <>
        <div className="main-container register" >
          <video id="myVideo" loop autoPlay muted playsInline>
            <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
          </video>
          <Header history={this.props.history} />
          <Grid container 
            alignItems="center" >
            <Grid item sm={6} md={6} style={{ marginTop: '5%' }}>
              <Paper className={"registerPaper"} style={{ textAlign: "left" }}>
                <span className="cross"><Link to="/"><CloseIcon /></Link></span>
                <div data-test="container" class="container">
                  <div data-test="row" class="row">
                    <div data-test="col" class="col-md-12">
                      <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        classNamePrefix="myLoader_"
                      >
                        <h3 class="form-title"><b>Request Access</b></h3>
                        <form className="dynamic-form" onSubmit={this.submitForm}>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                              value={formValues.email} onChange={this.handleChange} />
                            <div className="invalid-feedback">{formErrors.email}</div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="firstName">FirstName</label>
                            <input type="text" name="firstName" id="firstName" className={`form-control ${formErrors.firstName ? "is-invalid" : ""}`} value={formValues.firstName} onChange={this.handleChange} />
                            <div className="invalid-feedback">{formErrors.firstName}</div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="lastName">LastName</label>
                            <input type="text" name="lastName" id="lastName" className={`form-control ${formErrors.lastName ? "is-invalid" : ""}`} value={formValues.lastName} onChange={this.handleChange} />
                            <div className="invalid-feedback">{formErrors.lastName}</div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="companyOrOrganization">Company/Organization</label>
                            <input type="text" name="companyOrOrganization" id="companyOrOrganization" className={`form-control ${formErrors.companyOrOrganization ? "is-invalid" : ""}`} value={formValues.companyOrOrganization} onChange={this.handleChange} />
                            <div className="invalid-feedback">{formErrors.companyOrOrganization}</div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="tel" name="phone" id="phone" className={`form-control ${formErrors.phone ? "is-invalid" : ""}`} value={formValues.phone} onChange={this.handleChange} />
                            <div className="invalid-feedback">{formErrors.phone}</div>
                          </div>
                          <div className="form-actions">
                            <button type="submit" disabled={!formValidity.email || !formValidity.companyOrOrganization || !formValidity.firstName || !formValidity.lastName || !formValidity.phone} className="btn-indigo btn Ripple-parent btn btn-primary" id="submitButton" >
                              Submit
                              <div className="Ripple" style={{ top: "0px", left: "0px", width: "0px", height: "0px" }}></div>
                            </button>
                          </div>
                        </form>
                      </LoadingOverlay>
                    </div>
                  </div>
                </div>
                <p className="alreadyLink" style={{ textAlign: "center", padding: "5px", fontSize: "15px" }}><Link to="/login"> ALREADY REGISTERED? LOGIN HERE!</Link></p>
              </Paper>
            </Grid>
          </Grid>
          <Snackbar open={showMsg} autoHideDuration={9000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity={severity} >{message}</Alert></Snackbar>

        </div >
        <Footer />
      </>
    );
  }
}

export default Register;
