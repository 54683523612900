import { createContext } from 'react';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useHistory
} from 'react-router-dom';
import {
    clearUserData,
    getRefreshToken,
    setUserData,
    updateAccessToken,
} from '../util/auth';
import {
    deleteData,
    post
} from '../api/api';
import { REFRESH_TOKEN } from '../api/baseURL';

const AuthContext = createContext();
export default AuthContext;

// Time interval to update access token
const ACCESS_TOKEN_EXPIRES_TIME = 1000 * 60 * 9; // 9 min

export const AuthProvider = (props) => {
    // If user object is in local Storage, set initial state for authToken as user.accessToken
    let [authToken, setAuthToken] = useState(() => {
        const accessToken = localStorage.getItem('accessToken');
        // If accessToken is found in local Storage, set authToken to the accessToken value
        if (accessToken) {
            return accessToken;
        }
        // Else set initial state for authToken to null
        return null;
    })
    const refreshToken = getRefreshToken() || null;
    const [isFirstMounted, setIsFirstMounted] = useState(true);
    const history = useHistory();

    const onLogin = (userData) => {
        // Store userData as user object in localStorage
        setUserData(userData);
        // Update authToken state
        setAuthToken(userData.accessToken);
    }

    const handleLogout = async () => {
        // Remove user object from localStorage
        clearUserData();
    };

    async function updateRefreshtoken() {
        post(REFRESH_TOKEN, {
            refreshToken
        }).then(response => {
            if (response.status === 200) {
                const newToken = response.data.accessToken
                // Replace authToken state variable with new generated access token
                setAuthToken(newToken);
                // Replace accessToken stored in user object from localStorage with new generated access token  
                updateAccessToken(newToken);
            }
        }).catch(error => {
            // Clear objects in localStorage
            clearUserData();

            const currentPath = history.location.pathname;
            // If user is currently on private route. redirect them to login page
            const isPrivateRoute = currentPath.startsWith('/entitymanagement') ||
                currentPath.startsWith('/emailalert') ||
                currentPath.startsWith('/dashboard') ||
                currentPath.startsWith('/userManagement') ||
                currentPath.startsWith('/changePassword');
            if (isPrivateRoute) {
                history.push('/login');
            }
            return
        })
        if (isFirstMounted) {
            setIsFirstMounted(false);
        }
    }

    useEffect(() => {
        // Render updateRefreshToken function only on initial render and every 9 minutes
        if (isFirstMounted) {
            updateRefreshtoken();
        }
        // Obtain new access token with refresh token every 9 minutes
        const intervalId = setInterval(() => {
            if (authToken) {
                updateRefreshtoken()
            }
        }, ACCESS_TOKEN_EXPIRES_TIME);
        return () => { clearInterval(intervalId); }
    }, [authToken, isFirstMounted]);

    // Store token, onLogin and onLogout as global value 
    const value = useMemo(() => ({
        token: authToken,
        onLogin: onLogin,
        onLogout: handleLogout,
    }), [authToken])

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
}

