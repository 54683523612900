import React from "react";
import './login.scss';
import '../../App.css';
import DynamicForm from 'f1-dynamic-form-react';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { post, get } from '../../api/api';
import { TWO_FACTOR_AUTH, USER_ROLES } from '../../api/baseURL'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Footer from '../footer/footer';
import AuthContext from '../../routes/authContext';


class TwoFactorAuth extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: "",
      errorType: "",
      current: {},
      FormJsonOtp: [{
        "key": "otp",
        "label": "One-Time Passcode",
        "type": "string",
        "placeholder": "One-Time Passcode",
        "required": true,
        "validators": [
          { "name": "required", "value": true }
        ]
      }]

    };
  }


  onSignupOtp = (model) => {
    const { userData, ip, search, pathname } = this.props.location.state
    const email = userData.email
    post(TWO_FACTOR_AUTH + email + '&twoFactorCode=' + model.otp, { "ip": ip })
      .then(res => {
        this.context.onLogin(userData)
        if (search && pathname) {
          this.props.history.push({ pathname: pathname, search: search, state: { from: '/' } });
        } else {
          this.props.history.push('/dashboard')
        }
      }).catch(error => {
        if (error) {
          this.setState({ message: error.data.messages, show: true, errorType: "error" })
        } else {
          this.setState({ message: "Oops, the site can't be reached.", show: true })
        }

      })

    get(USER_ROLES + email
    ).then(response => {
      localStorage.setItem("role", response.data.role);
    }).catch(error => {
      console.log("Error on retrieving user roles:", error)
    })

  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false })
  };

  render() {
    return (
      <>
        <div className="main-container boxPage otpPage">
          <Header history={this.props.history} />

          <Grid container >
            <Grid item sm={4} md={4} >   </Grid>
            <Grid item sm={4} md={4} className="loginBox" >
              <Snackbar open={this.state.show} autoHideDuration={1000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={this.state.errorType} >{this.state.message}</Alert>
              </Snackbar>
              <Paper className={""} style={{ textAlign: "left" }}>

                <DynamicForm
                  key={this.state.current.id}
                  className="form"
                  title="Verification"
                  buttonSubmit="Submit"
                  defaultValues={this.state.current}
                  model={this.state.FormJsonOtp}
                  onSubmit={(model) => {
                    this.onSignupOtp(model);
                  }}
                  disableSubmitOnInValid={false}
                  customValidators={this.customValidators}
                  displayInformation={true}
                  displaydetails="You will get One-Time Passcode on registered mail id."
                  design="bootstrap" // material
                  extErrors={this.state.extErrors}// pass external error messages here if any
                  onAutocompleteSelect={this.onAutocompleteSelect}
                  autoCompleteItems={this.autoCompleteItems}
                />
              </Paper>

            </Grid>


          </Grid>


        </div>
        <Footer />
      </>
    );
  }
}
TwoFactorAuth.contextType = AuthContext;
export default TwoFactorAuth;
