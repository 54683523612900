import React from 'react';
import '../../App.css';
import Header from '../header/header';
import Grid from '@material-ui/core/Grid';
import { get } from '../../api/api';
import { GET_TICKER, DASHBOARD_PAGINATION, DESTINATION } from '../../api/baseURL';
import './dashboard.scss'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import MatDatagrid from 'f1-mat-data-grid-react';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import ClearIcon from '@material-ui/icons/Clear';
import { withRouter } from 'react-router-dom';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: localStorage.getItem("email") || "",
            selectedDatefrom: "",
            selectedDateto: new Date(),
            lastDate: "last30days",
            fullOwner: false,
            miles: "25",
            abnormalFlight: false,
            watchList: false,
            flightsData: [],
            flightsDataAll: [],
            allFlightsData: [],
            gridData: [],
            destinationData: [],
            loader: false,
            record: 50,
            readOnlyDatePicker: true,
            totalCount: 1,
            refresh: true,
            planeId: "", entityName: "", destinationId: "", ownerShip: "", planeModel: "", departure: "", arrival: "", departureDate: "", arrivalDate: "", abnormal: "", sortBy: "ArrivalDateTime", sortOrder: "desc", pageNumber: 1, perPage: 50, fromDate: "", toDate: "", sortByDate30: "", sortByDate90: "", pmiles: "25", entityNameTicker: "", searchText: "", userWatchList: ""
        }
    }

    ///Ticker and entity name field on change 
    onChangeTickers = (e, value) => {
        let updatedData = [];
        value && value.map(item => {
            updatedData.push(item.entityName)
        })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        this.setState({ entityNameTicker: encodeURIComponent(updatedData.join('|')) })
        this.setState({ loader: true })
        let url = '_p_EntityNameTickerFilter=' + encodeURIComponent(updatedData.join('|')) +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    }

    handleDateChangefrom = (date) => {
        var isValid = moment(date, 'YYYY-MM-DD hh:mm:ss').isValid()
        if (isValid) {
            if (this.state.lastDate == "custom") {
                this.setState({ selectedDatefrom: date, fromDate: moment(date).format('YYYY-MM-DD hh:mm:ss') })
                this.setState({ loader: true })
                let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                    '&_p_destinationId=' + this.state.destinationId +
                    '&_p_miles=' + this.state.pmiles +
                    '&_p_FromDate=' + moment(date).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_ToDate=' + moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_SortbyDate30=' + '' +
                    '&_p_SortbyDate90=' + '' +
                    '&_p_OwnerShipFilter=' + this.state.ownerShip +
                    '&_p_AbNormalFilter=' + this.state.abnormal +
                    '&_page_number=' + 1 +
                    '&_count_per_page=' + this.state.perPage +
                    '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
                    '&_p_WatchListFilter=' + this.state.userWatchList +
                    '&_p_email=' + this.state.userEmail;
                this.dashboardPagination(url);
                this.setState({ pageNumber: 1 })
            }
        }
    };
    handleDateChangeto = (date) => {
        var isValid = moment(date, 'YYYY-MM-DD hh:mm:ss').isValid()
        if (isValid) {
            if (this.state.lastDate == "custom") {
                this.setState({ selectedDateto: date, toDate: moment(date).format('DD/MM/YYYY HH:mm:ss') })
                this.setState({ loader: true })
                this.setState({ pageNumber: 1 })
                let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                    '&_p_destinationId=' + this.state.destinationId +
                    '&_p_miles=' + this.state.pmiles +
                    '&_p_FromDate=' + moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_ToDate=' + moment(date).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_SortbyDate30=' + this.state.sortByDate30 +
                    '&_p_SortbyDate90=' + this.state.sortByDate90 +
                    '&_p_OwnerShipFilter=' + this.state.ownerShip +
                    '&_p_AbNormalFilter=' + this.state.abnormal +
                    '&_page_number=' + 1 +
                    '&_count_per_page=' + this.state.perPage +
                    '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
                    '&_p_WatchListFilter=' + this.state.userWatchList +
                    '&_p_email=' + this.state.userEmail;
                this.dashboardPagination(url);
                this.setState({ pageNumber: 1 })
            }
        }
    };

    handleabnormalFlightCheck = (event) => {
        this.setState({ abnormalFlight: event.target.checked })
        this.setState({ abnormal: event.target.checked ? event.target.checked : "" })
        this.setState({ loader: true })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let abnormalData = event.target.checked ? event.target.checked : ""
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + abnormalData +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    };

    handlefullOwnerCheck = (event) => {
        this.setState({ fullOwner: event.target.checked })
        this.setState({ loader: true })
        this.setState({ ownerShip: event.target.checked ? event.target.checked : "" })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let fullOwnerData = event.target.checked ? event.target.checked : ""
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + fullOwnerData +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    };

    handleWatchlistCheck = (event) => {
        const isChecked = event.target.checked;
        this.setState({
            watchList: event.target.checked,
            userWatchList: event.target.checked ? event.target.checked : "",
            loader: true
        })
        let watchListData = event.target.checked ? event.target.checked : "";
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }

        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + watchListData +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    };

    milesChange = (e) => {
        this.setState({ pmiles: e.target.value })
        this.setState({ miles: e.target.value })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        if (this.state.Arrival != '' && typeof this.state.Arrival != "undefined" && this.state.Arrival != "undefined") {
            let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                '&_p_destinationId=' + this.state.destinationId +
                '&_p_miles=' + e.target.value +
                '&_p_FromDate=' + fromDate +
                '&_p_ToDate=' + toDate +
                '&_p_SortbyDate30=' + this.state.sortByDate30 +
                '&_p_SortbyDate90=' + this.state.sortByDate90 +
                '&_p_OwnerShipFilter=' + this.state.ownerShip +
                '&_p_AbNormalFilter=' + this.state.abnormal +
                '&_page_number=' + 1 +
                '&_count_per_page=' + this.state.perPage +
                '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
                '&_p_WatchListFilter=' + this.state.userWatchList +
                '&_p_email=' + this.state.userEmail;
            this.dashboardPagination(url);
            this.setState({ pageNumber: 1 })
        }
    }

    getFlights = (page) => {
        this.setState({ loader: true })
        this.setState({ sortByDate30: true })
        this.setState({ readOnlyDatePicker: true })
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + '' +
            '&_p_ToDate=' + '' +
            '&_p_SortbyDate30=' + true +
            '&_p_SortbyDate90=' + '' +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + this.state.pageNumber +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    }
    getTicker = () => {
        get(GET_TICKER).then(res => {
            this.setState({ allFlightsData: res.data })
        }).catch(error => {

        })
    }

    getDestination = () => {
        get(DESTINATION).then(res => {
            let destination = res.data;
            this.setState({ destinationData: destination })
        })
    }

    _stateUpdated() {
        // console.log(this.state.selectedDatefrom);
    }

    setDate = (newDate) => {
        var d = new Date();
        this.setState(
            { selectedDatefrom: d.setFullYear(d.getFullYear() - 1) },
            this._stateUpdated.bind(this)
        );
    }

    componentDidMount() {
        this.getTicker()
        this.getDestination()
        const selectedPlaneID = this.props.selectedPlaneID || "";
        const flightDateFrom = this.props.flightDateFrom || "";
        const flightDateTo = this.props.flightDateTo || "";
        const watchlistSet = this.props.isWatchlistSet || "";

        if (selectedPlaneID && flightDateFrom && flightDateTo) {
            this.setState({
                lastDate: 'custom', readOnlyDatePicker: false,
                selectedDatefrom: flightDateFrom, selectedDateto: flightDateTo, loader: true, searchText: selectedPlaneID
            }, () => {
                let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                    '&_p_destinationId=' + this.state.destinationId +
                    '&_p_miles=' + this.state.pmiles +
                    '&_p_FromDate=' + moment(flightDateFrom).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_ToDate=' + moment(flightDateTo).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_SortbyDate30=' + '' +
                    '&_p_SortbyDate90=' + '' +
                    '&_p_OwnerShipFilter=' + this.state.ownerShip +
                    '&_p_AbNormalFilter=' + this.state.abnormal +
                    '&_page_number=' + this.state.pageNumber +
                    '&_count_per_page=' + this.state.perPage +
                    '&_p_SearchText=' + selectedPlaneID +
                    '&_p_WatchListFilter=' + this.state.userWatchList +
                    '&_p_email=' + this.state.userEmail;
                this.dashboardPagination(url);
                this.setState({ pageNumber: 1 })
            })
        } else if (watchlistSet) {
            // User clicked on view full daily flights link
            // If user has not setup watchlist, no watchlist filter is needed
            if (watchlistSet == 'yes') {
                this.setState({ watchList: true, userWatchList: true })
            }

            this.setState({
                lastDate: 'custom', readOnlyDatePicker: false,
                selectedDatefrom: flightDateFrom, selectedDateto: flightDateTo, loader: true
            }, () => {
                let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                    '&_p_destinationId=' + this.state.destinationId +
                    '&_p_miles=' + this.state.pmiles +
                    '&_p_FromDate=' + moment(flightDateFrom).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_ToDate=' + moment(flightDateTo).format('YYYY-MM-DD hh:mm:ss') +
                    '&_p_SortbyDate30=' + '' +
                    '&_p_SortbyDate90=' + '' +
                    '&_p_OwnerShipFilter=' + this.state.ownerShip +
                    '&_p_AbNormalFilter=' + this.state.abnormal +
                    '&_page_number=' + this.state.pageNumber +
                    '&_count_per_page=' + this.state.perPage +
                    '&_p_SearchText=' + '' +
                    '&_p_WatchListFilter=' + this.state.userWatchList +
                    '&_p_email=' + this.state.userEmail;
                this.dashboardPagination(url);
                this.setState({ pageNumber: 1 })
            })
        } else {
            this.getFlights()
            this.setDate()
        }
    }

    handleChange = (e) => {
        this.setState({ lastDate: e.target.value })
        if (e.target.value === "custom") {
            this.setState({ readOnlyDatePicker: false })
            this.setState({ sortByDate30: '' })
            this.setState({ sortByDate90: '' })
            this.setState({ loader: true })
            let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                '&_p_destinationId=' + this.state.destinationId +
                '&_p_miles=' + this.state.pmiles +
                '&_p_FromDate=' + moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss') +
                '&_p_ToDate=' + moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss') +
                '&_p_SortbyDate30=' + '' +
                '&_p_SortbyDate90=' + '' +
                '&_p_OwnerShipFilter=' + this.state.ownerShip +
                '&_p_AbNormalFilter=' + this.state.abnormal +
                '&_page_number=' + 1 +
                '&_count_per_page=' + this.state.perPage +
                '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
                '&_p_WatchListFilter=' + this.state.userWatchList +
                '&_p_email=' + this.state.userEmail;
            this.dashboardPagination(url);
            this.setState({ pageNumber: 1 })
        } else if (e.target.value === "last90days") {
            this.setState({ loader: true })
            this.setState({ sortByDate30: '' })
            this.setState({ sortByDate90: true })
            this.setState({ readOnlyDatePicker: true })
            let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                '&_p_destinationId=' + this.state.destinationId +
                '&_p_miles=' + this.state.pmiles +
                '&_p_FromDate=' + '' +
                '&_p_ToDate=' + '' +
                '&_p_SortbyDate30=' + '' +
                '&_p_SortbyDate90=' + true +
                '&_p_OwnerShipFilter=' + this.state.ownerShip +
                '&_p_AbNormalFilter=' + this.state.abnormal +
                '&_page_number=' + 1 +
                '&_count_per_page=' + this.state.perPage +
                '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
                '&_p_WatchListFilter=' + this.state.userWatchList +
                '&_p_email=' + this.state.userEmail;
            this.dashboardPagination(url);
            this.setState({ pageNumber: 1 })
        } else {
            this.getFlights()
        }
    }

    onChangePage = (e, page) => {
        this.setState({ pageNumber: page, refresh: true })
        this.setState({ loader: true })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + page +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
    }

    departureChange = (e, value) => {
        this.setState({ destinationId: value ? value.ArrivalId : "" })
        this.setState({ Arrival: value ? value.Arrival : "" })
        this.setState({ loader: true })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let arr = value ? value.ArrivalId : "";
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + arr +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    }

    recordPerPage = (e) => {
        this.setState({ record: e.target.value, perPage: e.target.value })
        this.setState({ loader: true })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + e.target.value +
            '&_p_SearchText=' + encodeURIComponent(this.state.searchText) +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    }

    onClear = () => {
        this.setState({ searchText: "" })
        this.setState({ loader: true })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
            '&_p_destinationId=' + this.state.destinationId +
            '&_p_miles=' + this.state.pmiles +
            '&_p_FromDate=' + fromDate +
            '&_p_ToDate=' + toDate +
            '&_p_SortbyDate30=' + this.state.sortByDate30 +
            '&_p_SortbyDate90=' + this.state.sortByDate90 +
            '&_p_OwnerShipFilter=' + this.state.ownerShip +
            '&_p_AbNormalFilter=' + this.state.abnormal +
            '&_page_number=' + 1 +
            '&_count_per_page=' + this.state.perPage +
            '&_p_SearchText=' + "" +
            '&_p_WatchListFilter=' + this.state.userWatchList +
            '&_p_email=' + this.state.userEmail;
        this.dashboardPagination(url);
        this.setState({ pageNumber: 1 })
    }

    onSearchChange = (event, text) => {
        this.setState({ searchText: event.target.value })
        let fromDate, toDate;
        if (this.state.lastDate == 'custom') {
            fromDate = moment(this.state.selectedDatefrom).format('YYYY-MM-DD hh:mm:ss');
            toDate = moment(this.state.selectedDateto).format('YYYY-MM-DD hh:mm:ss');
        }
        else {
            fromDate = '';
            toDate = '';
        }
        if (event.key === 'Enter') {

            this.setState({ loader: true })
            let url = '_p_EntityNameTickerFilter=' + this.state.entityNameTicker +
                '&_p_destinationId=' + this.state.destinationId +
                '&_p_miles=' + this.state.pmiles +
                '&_p_FromDate=' + fromDate +
                '&_p_ToDate=' + toDate +
                '&_p_SortbyDate30=' + this.state.sortByDate30 +
                '&_p_SortbyDate90=' + this.state.sortByDate90 +
                '&_p_OwnerShipFilter=' + this.state.ownerShip +
                '&_p_AbNormalFilter=' + this.state.abnormal +
                '&_page_number=' + 1 +
                '&_count_per_page=' + this.state.perPage +
                '&_p_SearchText=' + event.target.value +
                '&_p_WatchListFilter=' + this.state.userWatchList +
                '&_p_email=' + this.state.userEmail;
            this.dashboardPagination(url);
            this.setState({ pageNumber: 1 })
        }
    }

    dashboardPagination(url) {
        get(DASHBOARD_PAGINATION + url).then(res => {
            let dashboradData = res.data
            this.setState({ gridData: res.data, refresh: true })
            this.setState({ totalCount: dashboradData[0].TotalRecords })
            this.setState({ loader: false })
        });
    }

    render() {
        let totalPages = Math.ceil(this.state.totalCount / this.state.record);

        return (
            <>
                <div className="main-container-inner dashboard">
                    <Header history={this.props.history} />
                    <div className="filter-div">
                        {this.state.loader ?
                            <CircularProgress color="secondary" className="loader" /> : ""}
                        <Grid container >
                            <Grid sm={5} xs={12} style={{ paddingRight: '10px' }}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    freeSolo
                                    onMouseDownCapture={(e) => e.stopPropagation()}
                                    options={this.state.allFlightsData}
                                    onChange={(event, value) => this.onChangeTickers(event, value)}
                                    value={this.state.company}
                                    autoHighlight={true}
                                    getOptionLabel={(option) => option.entityName ? option.entityName : ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Entity Name or Ticker"
                                            placeholder="Enter Entity Name or Ticker"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid sm={4} xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    freeSolo
                                    onMouseDownCapture={(e) => e.stopPropagation()}
                                    options={this.state.destinationData}
                                    getOptionLabel={(option) => option.Arrival == null ? "" : option.Arrival}
                                    noOptionsText={"No records to display"}
                                    onChange={(e, value) => this.departureChange(e, value)}
                                    popoverProps={{
                                        open: false
                                    }}

                                    renderInput={(params) => <TextField {...params} label="Enter Arrival Location" variant="outlined" />}
                                />
                            </Grid>

                            <Grid sm={3} xs={12}>
                                <select value={this.state.miles} onChange={this.milesChange} className="form-control selectbox">
                                    <option value={"10"}>10 Miles</option>
                                    <option value={"25"}>25 Miles</option>
                                    <option value={"50"}>50 Miles</option>

                                </select>
                            </Grid>
                            <Grid sm={5} xs={12} className="rightPanel">
                                <FormControl component='fieldset' className='rightPanel'>
                                    <div className='MuiFormGroup-root' style={{ flexDirection: 'row' }}>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked={this.state.fullOwner} onChange={this.handlefullOwnerCheck} name="checkedA" />}
                                            label="Full Ownership Only"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked={this.state.abnormalFlight} onChange={this.handleabnormalFlightCheck} name="checkedA" />}
                                            label="Abnormal Flights Only"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.watchList} onChange={this.handleWatchlistCheck} name="checkedA" />}
                                            label="Watchlist Only"
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid sm={4} xs={12}>
                                <FormControl component="fieldset" className="leftPanel">
                                    <RadioGroup aria-label="dateT" name="dateT" value={this.state.lastDate} onChange={this.handleChange}>
                                        <FormControlLabel value="last30days" control={<Radio />} label="Last 30 Days" />
                                        <FormControlLabel value="last90days" control={<Radio />} label="Last 90 Days" />
                                        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid sm={3} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline-to"
                                        label=""
                                        value={this.state.selectedDatefrom}
                                        onChange={this.handleDateChangefrom}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.readOnlyDatePicker}
                                        autoOk={true}
                                        okLabel
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline-to"
                                        label=""
                                        value={this.state.selectedDateto}
                                        onChange={this.handleDateChangeto}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        disabled={this.state.readOnlyDatePicker}
                                        okLabel
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="matGrid">
                        <div id="searchBar">
                            <Input
                                value={this.state.searchText}
                                onChange={this.onSearchChange}
                                onKeyPress={this.onSearchChange}
                                endAdornment={<InputAdornment position="end"><ClearIcon className="clear" onClick={this.onClear} /></InputAdornment>}
                                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                placeholder="Search by Other Criteria: Plane Id, Ownership Type, Plane Model, Departure Location, Arrival Location"
                            />
                        </div>
                        {this.state.refresh ?
                            <MatDatagrid
                                rows={(this.state.gridData && this.state.gridData.length > 1) ? this.state.gridData : []}
                                columns={
                                    [
                                        { title: 'Plane Id', field: 'PlaneId' },
                                        { title: 'Entity Name', field: 'EntityName', sorting: true, resizable: true },
                                        { title: 'Ownership Type', field: 'Ownership', sorting: true },
                                        { title: 'Plane Model', field: 'PlaneModel', sorting: true },
                                        { title: 'Departure Location', field: 'Departure', sorting: true },
                                        { title: 'Departure Date/Time (UTC)', field: 'DepartureDateTime' },
                                        { title: 'Arrival Location', field: 'Arrival' },
                                        { title: 'Arrival Date/Time (UTC)', field: 'ArrivalDateTime' },
                                        { title: 'Abnormal', field: 'Abnormal' }
                                    ]
                                }
                                search={false}
                                paging={false}
                                pageSize={this.state.record}
                                filtering={false}
                                exportButton={true}
                                localization={{ toolbar: { searchPlaceholder: 'Search by Plane Id,Ownership,Plane Model,Departure,Arrival', searchTooltip: "" } }}
                                title="Dashboard"                 //Export to csv and pdf
                                exportCsv={this.csvExp}
                                stickyHeader={{ show: true, maxBodyHeight: '52vh' }}            //custom csv                                                       
                                customPagination={{
                                    isDisplay: true,
                                    totalCount: this.state.totalCount,
                                }}
                                onSearchChange={this.onSearchChange}
                            /> : ''}
                        {this.state.refresh ?
                            <Pagination className="pagi" count={totalPages} page={this.state.pageNumber} variant="outlined" onChange={this.onChangePage} /> : ''}
                        <span className="perPageDiv">Records per page<select value={this.state.record} onChange={this.recordPerPage}>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                            <option value={"150"}>150</option>
                            <option value={"200"}>200</option>
                        </select>
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Dashboard);
