import React from 'react';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Footer from "../footer/footer";
import './legalAndDisclosures.scss';


class PrivacyPolicy extends React.Component {
  
  
  render() {
    return (
      <>
      <div className="main-container boxPage privacyPolicy">
      <video id="myVideo" loop muted autoPlay playsInline>
 <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
</video>
        <Header history={this.props.history}/>
          
        <Grid container >
       
          <Grid item sm={12} md={12} className="legalAndDisclosures" >
        <div className="inner-div">
        <span className="cross"><Link to="/"><CloseIcon/></Link></span>
            <h3>Privacy Policy</h3>
            <br/>
            <p>Gordon Haskett Research Advisors (“GHRA”) needs to collect and store certain kinds of private and confidential information including but not limited to your business name, address, telephone number. GHRA will use this information only in ways that are in accord with the purposes for which we originally requested it. We also collect passwords, but we can’t access them. Under no circumstances do we sell, rent, or give your personal information to a third party nor do we provide your e-mail address to a third party for the purpose of that party sending you “spam mail” or otherwise using your email address in any way not directly associated with GHRA providing its Services to you.</p>
            <p>If a Subscriber accesses the GHRA Jet Website, our Website automatically recognizes the accessing computer’s “domain name”, commonly referred to as the “IP” (Internet Protocol) address, which consists of a set of numbers that uniquely identify the accessing computer. While the IP alone does not provide or disclose any personal, identifiable information, it does identity the IP of the accessing computer. From time to time, we examine our traffic in aggregate to help us improve our Website and to maintain the quality of the service. </p>
            <p>We have other policies that govern your use of the Site, including, without limitation, our “Terms of Use” to which you consent and unconditionally agree each time you log into our Site. Moreover, in the future we may implement certain other policies, terms, and conditions that will impact your use of the Site.  We reserve the right, at our discretion, to change, modify, add, or remove portions from this Privacy Policy at any time.</p>
              </div>
          </Grid>
        </Grid>
      </div>
         <Footer linkSection=""/>
      </>
    );
  }
}

export default PrivacyPolicy;
