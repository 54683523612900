import React, { ReactDOM } from 'react';
import { get, post } from '../../api/api';
import { UPDATE_NEW_USER, DELETE_USER, CREATE_USER, ALL_USERS } from '../../api/baseURL';
import ModalPopup from 'f1-modal-react'
import Paper from '@material-ui/core/Paper';
import Header from '../header/header';
import DynamicForm from 'f1-dynamic-form-react';
import Footer from '../footer/footer'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MatDatagrid from 'f1-mat-data-grid-react';
import './userManagement.scss';
import publicIp from "public-ip";
import Button from '@material-ui/core/Button';
import { encryptData } from '../../util/utils';

class UserManagement extends React.Component {
  constructor() {
    super();
    this.dynamicFormRef = React.createRef();
    this.state = {
      flow: "",
      current: {
      },
      severity: '',
      isAdmin: false,
      showMsg: false,
      ip: "",
      message: "",
      deleteEmail: "",
      IsAdminEmail: "",
      modalShow: false,
      modalDeleteShow: false,
      modalAdminshow: false,
      formData: [
        { "key": "email", "type": "email", "disabled": false, "id": "email", "icon": "user", "label": "Email", "validators": [{ "name": "required", "value": true }, { "name": "isEmail", "value": true }] },
        { "key": "password", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Password", "validators": [{ "name": "required", "value": true }, { "name": "isPassword", "value": true }] },
        { "key": "firstName", "disabled": false, "id": "firstName", "icon": "user", "label": "First Name", "validators": [{ "name": "required", "value": true }] },
        { "key": "lastName", "disabled": false, "id": "lastName", "icon": "user", "label": "Last Name", "validators": [{ "name": "required", "value": true }] },
        { "key": "companyOrOrganization", "disabled": false, "id": "companyOrOrganization", "icon": "user", "label": "Company/Organization", "validators": [{ "name": "required", "value": true }] },
        { "key": "phone", "type": "tel", "disabled": false, "id": "phone", "icon": "user", "label": "Phone", "validators": [{ "name": "required", "value": true }] },
        {
          "key": "isApproved", "label": "Status", disabled: false,
          "type": "select",
          "id": "isApproved",
          "options": [
            { "key": "Select", "label": "Select", "value": "" },
            { "key": "1", "label": "Approved", "value": "1" },
            { "key": "2", "label": "Trial User", "value": "2" },
            { "key": "0", "label": "Pending", "value": "0" }
          ],
          "validators": [{ "name": "required", "value": true }]
        },
        {
          "key": "isActive", "label": "Active",
          "type": "select",
          "id": "isActive",
          "options": [
            { "key": "Select", "label": "Select", "value": "" },
            { "key": "yes", "label": "Yes", "value": "Yes" },
            { "key": "no", "label": "No", "value": "No" }
          ],
          "validators": [{ "name": "required", "value": true }]
        },
        {
          "key": "isAdmin", "label": "Is Admin",
          "type": "select",
          "id": "isAdmin",
          "options": [
            { "key": "Select", "label": "Select", "value": "" },
            { "key": "yes", "label": "Yes", "value": "Yes" },
            { "key": "no", "label": "No", "value": "No" }
          ],
          "validators": [{ "name": "required", "value": true }]
        }
      ],
      users: [],
      deleteData: {},
      isAdminSelected: ""
    }

  }

  onEdit = (props) => {
    if (props.IsApproved == 0) {
      props.IsActive = false;
    }
    this.setState({ flow: "Edit", modalShow: !this.state.modalShow })
    this.setState({ IsAdminEmail: props.Email })
    let schema = {
      "email": props.Email,
      "password": props.Password,
      "firstName": props.FirstName,
      "lastName": props.LastName,
      "companyOrOrganization": props.CompanyOrOrganization,
      "companyTicker": "",
      "abnormalityNotified": true,
      "phone": props.Phone,
      "isApproved": props.IsApproved.toString(),
      "isActive": props.IsActive == true ? "Yes" : "No",
      "isAdmin": props.IsAdmin == true ? "Yes" : "No",
      "loginCount": 0

    }
    this.setState({ current: schema })
    let updatedFormData = this.state.formData.map(item => {
      if (item.key == "isApproved") {
        // Disable isApproved field if user is in approve status
        item.disabled = props.IsApproved == 1 ? true : false
      }
      if (item.key == "password") {
        // Disable password field if user is in trial status or approve status
        item.disabled = props.IsApproved == 1 || props.IsApproved == 2;
      }

      return item
    })
    this.setState({ formData: updatedFormData })
  }

  onDelete = () => {
    let schema = {
      "email": this.state.deleteData.Email,
      "password": this.state.deleteData.Password,
      "IPAddress": this.state.ip
    }

    post(DELETE_USER, schema).then(res => {
      this.setState({ message: "User Deleted", showMsg: true, modalDeleteShow: false, severity: 'success' })
      get(ALL_USERS).then(res => {
        this.setState({ users: res.data })
      })
    }).catch(err => {
      this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: 'error' })
    })
  }

  onEnter = () => {

  }

  onExit = (e) => {

  }

  submitForm = (event, value) => {

    const requiredFields = ['email', 'firstName', 'lastName', 'password','companyOrOrganization', 'phone', 'isApproved', 'isActive', 'isAdmin']
    const missingFields = requiredFields.filter(field => event[field] === undefined || event[field] === '');

    if (missingFields.length > 0) {
      this.setState({ message: "Please fill all the required values.", showMsg: true, severity: 'error' })
    }
    else if (event['isApproved'] === '0' && event['password'] != '') {
      this.setState({ message: "No password should be provided for pending user. Please change the status to Trial User or Approved before submit.", showMsg: true, severity: 'error' })
    }
    else {
      let schema = {
        "email": event.email,
        "password": event.password,
        "firstName": event.firstName,
        "lastName": event.lastName,
        "companyOrOrganization": event.companyOrOrganization,
        "companyTicker": "",
        "abnormalityNotified": true,
        "phone": event.phone,
        "isApproved": parseInt(event.isApproved),
        "isActive": event.isActive == "Yes" ? true : false,
        "isAdmin": event.isAdmin == "Yes" ? true : false,
        "loginCount": 0
      }

      // Retrieve password value from current schema
      const password = schema.password
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const encryptedPassword = encryptData(password, secretKey);
      schema.password = encryptedPassword

      if (this.state.flow === "Edit") {
        post(UPDATE_NEW_USER, schema).then(res => {
          this.setState({ message: "User registered", showMsg: true, modalShow: false, severity: 'success' })

          get(ALL_USERS).then(res => {
            this.setState({ users: res.data })
          })
        }).catch(err => {
          this.setState({ message: "Oops, this site can't be reached.", showMsg: true, modalShow: false, severity: 'error' })
        })
      } else {
        // this.state.flow === "Add"
        post(CREATE_USER, schema).then(res => {
          this.setState({ message: "User added", showMsg: true, modalShow: false, severity: 'success' })

          get(ALL_USERS).then(res => {
            this.setState({ users: res.data })
          })
        }).catch(err => {
          if (err) {
            if (err.status === 404) {
              this.setState({ message: "The user is already registered.", showMsg: true, severity: 'error' })
            }
            else {
              this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: 'error' })
            }
          } else {
            this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: 'error' })
          }
        })
      }
    }
  };

  componentDidMount() {
    publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"]

    }).then(res => {
      this.setState({ ip: res })
    })
    get(ALL_USERS).then(res => {
      this.setState({ users: res.data })
    })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showMsg: false })
  };

  addUser = (newData) => {
    this.setState({ flow: "Add", current: {}, modalShow: true })
    let updatedFormData = this.state.formData.map(item => {
      if (item.key === "isApproved") {
        item.disabled = false
      }
      if (item.key === "password") {
        item.disabled = false
      }
      return item
    })
    this.setState({ formData: updatedFormData })
  };

  deleteConfirmation = (props) => {
    this.setState({ modalDeleteShow: true, deleteData: props, deleteEmail: props.email })

  };

  onChangeHandler = (event, key) => {
    if (key == "isAdmin" && event.target.value === "Yes") {
      this.setState({ current: { ...this.state.current, isAdmin: event.target.value }, modalAdminshow: true })
      this.setState({ IsAdminEmail: this.state.current.email })
    } else {
      this.setState({ current: { ...this.state.current, [key]: event.target.value } })
    }
  };

  onAdminClickYes = () => {
    // this.state.current.isAdmin="Yes"
    this.setState({ modalAdminshow: false })
    this.setState({ current: { isAdmin: "Yes" } })
  };

  onAdminClickNo = () => {
    this.setState({ current: { isAdmin: "No" } })
    this.setState({ modalAdminshow: false })
  };

  render() {
    return (<>
      <div className="main-container userManagement userGrid">
        <Header history={this.props.history} />
        <div className="analytics-section">
          <Paper style={{ textAlign: "left" }}>
            <h4 className="pageTitle" style={{ padding: "0 15px" }}>User Management <button className="adduser" onClick={this.addUser}>Add User</button></h4>
            <MatDatagrid
              rows={this.state.users}
              columns={
                [
                  { title: 'Email', field: 'Email' },
                  { title: 'First Name', field: 'FirstName', sorting: false },
                  // sorting false above
                  { title: 'Last Name', field: 'LastName', sorting: true },
                  {
                    title: 'Status', field: 'IsApproved', render: rowData => <span>
                      {rowData.IsApproved == 0 ? "Pending" : rowData.IsApproved == 1 ? "Approved" : "Trial User"}
                    </span>
                  },
                  {
                    title: 'Active', field: 'IsActive', render: rowData => <span>
                      {rowData.IsActive ? "Yes" : "No"}
                    </span>
                  },
                  {
                    title: 'Is Admin', field: 'IsAdmin', render: rowData => <span>
                      {rowData.IsAdmin ? "Yes" : "No"}
                    </span>
                  },
                  { title: 'Last Login', field: 'LastLoginDate', sorting: true },
                  { title: 'Current Login', field: 'CurrentLoginDate', sorting: true },
                  {
                    title: 'Actions', field: '', render: (props) => {
                      return <div><span className="showLink" onClick={() => this.onEdit(props)}>Edit</span> | <span className="showLink" onClick={() => this.deleteConfirmation(props)}>Delete</span></div>
                    }
                  }
                ]
              }
              search={true}
              title={"UserManagement"}
              stickyHeader={{ show: true, maxBodyHeight: '52.4vh' }}
            />
            <Snackbar open={this.state.showMsg} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity={this.state.severity} >{this.state.message}</Alert></Snackbar>
            <ModalPopup
              show={this.state.modalShow}
              closeButtonShow={false}
              onHide={() => this.setState({ modalShow: false })}
              size={"lg"}                                       // "lg","sm" or "xl"
              centered={true}                                   // vertically center the Dialog in the window
              modelHeader={this.state.flow === "Edit" ? "Edit User" : "Add User"}                     // to show model header
              modelContent={<DynamicForm
                onRef={ref => this.dyFormRef = ref}
                key={this.state.current.id}
                className="form horizontal"
                title=" "
                buttonSubmit="Submit"
                defaultValues={this.state.current}
                model={this.state.formData}
                onSubmit={this.submitForm}
                disableSubmitOnInValid={false}
                customValidators={this.customValidators}
                displayInformation={true}
                design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
                materialOutline={true}
                extErrors={this.state.extErrors}
                onChange={this.onChangeHandler}
              // defaultAccordianOpenStates={{fieldset2: true, fieldset1: false}}
              // renderFieldsetAccordianLabel={this.renderFieldsetAccordianLabel}
              />}             // to show model content
              // modelFooter={<button onClick={this.onclick}>Close</button>}             //// to show model footer
              backdrop={'static'}                               // true, false or 'static'
              scrollable={true}                                 //boolean
              onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
              onExit={this.onExit}                              //Callback fired right before the Modal transitions out
              closeButtonShow={true}
              parentClassName="addEditUser"
              headerClassName="ModalHeaderBackground"
              bodyClassName="ModalBodyBackground editUser"

            />
          </Paper>
        </div>
      </div>
      <Footer />
      <ModalPopup
        id={"delteUserPopup"}
        show={this.state.modalDeleteShow}
        onHide={() => this.setState({ modalDeleteShow: false })}
        size={"sm"}                                       // "lg","sm" or "xl"
        centered={true}                                   // vertically center the Dialog in the window
        modelHeader={"Delete User"}                     // to show model header
        modelContent={<p>Are you sure you want to delete this user &nbsp;<span>{this.state.deleteEmail}</span>?</p>}             // to show model content
        modelFooter={<><Button onClick={this.onDelete}>YES</Button><Button onClick={() => this.setState({ modalDeleteShow: false })}>NO</Button></>}             //// to show model footer
        backdrop={'static'}                               // true, false or 'static'
        scrollable={true}                                 //boolean
        onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
        onExit={this.onExit}                              //Callback fired right before the Modal transitions out
        closeButtonShow={true}
        parentClassName="ModalBackground"
        headerClassName="ModalHeaderBackground"
        bodyClassName="ModalBodyBackground"
        footerClassName="ModalFooterBackground"
      />

      <ModalPopup
        id={"confirmAdminPopup"}
        show={this.state.modalAdminshow}
        onHide={() => this.setState({ modalAdminshow: false })}
        size={"sm"}                                       // "lg","sm" or "xl"
        centered={true}                                   // vertically center the Dialog in the window
        modelHeader={"Grant Admin access"}                     // to show model header
        modelContent={<p>Are you sure you want to grant the permission for this user&nbsp;<span>{this.state.IsAdminEmail}</span>?</p>}             // to show model content
        modelFooter={<><Button onClick={this.onAdminClickYes}>YES</Button><Button onClick={this.onAdminClickNo}>NO</Button></>}             //// to show model footer
        backdrop={'static'}                               // true, false or 'static'
        scrollable={true}                                 //boolean
        onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
        onExit={this.onExit}                              //Callback fired right before the Modal transitions out
        closeButtonShow={true}
        parentClassName="ModalBackground"
        headerClassName="ModalHeaderBackground"
        bodyClassName="ModalBodyBackground"
        footerClassName="ModalFooterBackground"
      />
    </>
    )
  }
}
export default UserManagement;
