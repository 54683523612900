import React from "react";
import './header.scss'
import F1HeaderNav from 'f1-header-navigation-react'

class LoginHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: 1,
    };
  }
  logout = () => {
    this.props.history.push("/login");
  }

  logoClick = () => {
    this.props.history.push("/home");
  }

  CustomBrand = ({}) => <div className="my-custom-brand"> 
</div>

onItemClick = (item, child_item) =>{
if(item.id==="register"){
  this.props.history.push('/register')
}
if(item.id==="login"){
  this.props.history.push('/login')
}
if(item.id==="contact"){
  this.props.history.push('/contact')
}
if(item.id==="Home"){
  this.props.history.push('/')
}
}

 brandImgStyle = {width: '90px'}
 navBarStyle = {backgroundColor: '#225cb2'}
 navBarSimpleStyle = {backgroundColor: '#A9A9A9'}


  headerConfig = {
    navBarStyle : this.navBarStyle,
    navBarClassName : 'my-custom-nav-bar',
    brandType : 'text', // text/image/component, required
    brandText: <span className="branding"></span>, 
    brandImgUrl: "", // in case of type === image
    brandClass: 'my-brand', // custom class for brand styling
    brandStyle: this.brandImgStyle, // style to be apply on brand component
    disableHambergerIcon: true, // default true
    onItemClick: this.onItemClick,
    menus:{
        right:[         
        ],        
      }
  }
  render() {
    return (

      <div>
           <F1HeaderNav
            config={this.headerConfig}
            brandComponent={<this.CustomBrand />} // in case of type === component
          />
       </div>
    );
  }
}

export default LoginHeader;
