import React from "react";
import ghraLogo from "../../assets/img/GH-logo-research-advisors-white.svg";
import ghccLogo from "../../assets/img/GH-logo-capital-corp-white.svg";
import "./footer.scss";
import { Link } from "react-router-dom";
function Footer(props) {
  const openMailBox = (e) => {
    window.open("mailto:jets@gordonhaskett.com");
  };
  return (
    <footer role="contentinfo">
      <div className="container">
        <div className="rows">
          <div className="col-md-4 col-sm-4">
            {/* <strong>Gordon Haskett Research Advisors</strong><br /> */}
            <div>
              441 Lexington Avenue
              <br />
              New York, NY 10017
              <br />
              +1 212 883 0600
              <div>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    openMailBox(e);
                  }}
                >
                  jets@gordonhaskett.com
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-4 affiliate-section gap-2 md:gap-0">
            <span className="adjustSpan"> Our Affiliates</span>
            <div className="affiliate-logo">
              <a href="https://gordonhaskett.com/" target="_blank">
                <img src={ghraLogo} style={{ width: "205px" }} />
              </a>
            </div>
            {/* <div className="affiliate-logo">
              <a href="https://gordonhaskett.com/ghcc" target="_blank">
                <img src={ghccLogo} style={{ width: "205px" }} />
              </a>
            </div> */}
          </div>

          <div className="col-md-4 col-sm-4">
            {props.linkSection}
            <ul class="footer-links">
              <li>
                <Link
                  to={"/legalAndDisclosures"}
                  target="_blank"
                  style={{ color: "white" }}
                >
                  Legal and Disclosures
                </Link>
              </li>

              <li>
                <Link
                  to={"privacyPolicy"}
                  target="_blank"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </Link>
              </li>

              <li>
                <Link
                  to={"contactUs"}
                  target="_blank"
                  style={{ color: "white" }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
