import axios from 'axios';

export const defaultErrorHandler = error => {
  return Promise.reject(error);
};
export const errorHandler = error => {
  return Promise.reject(error.response);
};
const getInitializedApi = (containType, responseType = 'json') => {
  const token = localStorage.getItem('accessToken');
  const currentServiceTokenContext = token;
  let header = {
    Authorization: 'Bearer ' + token
  };
  let api = axios.create({
    responseType: responseType,
    withCredentials: false,
    headers: header.Authorization ? header : {
      'Accept': 'application/json',
      'Content-Type': containType,
      'X-JsonResponseCase': 'camel',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*'
    },
  });
  return api;
};

export const convertToFormUrlencodedValue = params => {
  return Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');
};
export const post = (url, data) => {
  return getInitializedApi('application/json')
    .post(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const put = (url, data) => {
  return getInitializedApi('application/json')
    .put(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const get = (url, data) => {
  return getInitializedApi('application/json')
    .get(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const getDownloadData = (url, data, Header, config) => {
  return getInitializedApi('application/vnd.ms-excel', 'blob')
    .post(url, data, Header, config)
    .catch(errorHandler || defaultErrorHandler);
};


export const deleteData = (url, data) => {
  return getInitializedApi('application/json')
    .delete(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
