import React from 'react';
import ghraLogo from '../../assets/img/GH-logo-research-advisors-white.svg';
import ghccLogo from '../../assets/img/GH-logo-capital-corp-white.svg';
import './footer.scss';
import { Link } from 'react-router-dom';
function Footer(props) {
const openMailBox = (e) => {
window.open('mailto:jets@gordonhaskett.com')

 }
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-4">
                        {/* <strong>Gordon Haskett Research Advisors</strong><br /> */}
                441 Lexington Avenue<br />
                New York,
                NY
                10017<br />
                +1 212 883 0600
                <div>
            <a onClick={e => {
                        e.preventDefault();
                        openMailBox(e);
                    }}>jets@gordonhaskett.com</a>
            </div>
            </div>

                    <div className="col-md-4 col-sm-4">
                       <span className="adjustSpan"> Our Affiliates</span><br />
                        <a href="https://gordonhaskett.com/"  target="_blank"><img src={ghraLogo} style={{width:"221px", marginLeft: "19px", marginTop: "10px"}} /></a><br />
                        <a href="https://gordonhaskett.com/ghcc" target="_blank"><img src={ghccLogo} style={{width:"221px", marginLeft: "19px", marginTop: "15px", marginBottom: "20px"}} /></a><br />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        {props.linkSection}
                        <div>
                            <Link to={'/legalAndDisclosures'} target="_blank">Legal and Disclosures</Link><br />
                            <Link to={'privacyPolicy'} target="_blank">Privacy Policy</Link><br />
                            <Link to={'contactUs'} target="_blank">Contact Us</Link><br />

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
