import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from "react-router-dom";
import Login from './components/login/login'
import Register from './components/register/register';
import ForgotPassword from './components/login/forgotPassword';
import ChangePassword from './components/changePassword/changePassword'
import TwoFactorAuth from './components/login/twoFactorAuth';
import ContactUs from './components/contactUs/contactUs';
import Home from './components/home/home';
import Dashboard from './components/dashboard/dashboard'
import LegalAndDisclosures from './components/legalAndDisclosures/legalAndDisclosures'
import PrivacyPolicy from './components/legalAndDisclosures/privacyPolicy';
import UserManagement from './components/userManagement/userManagement';
import EmailAlert from './components/emailAlert/emailAlert'
import EntityManagement from './components/entityManagement/entityManagement';
import { AuthProvider } from './routes/authContext';
import queryString from 'query-string';

const App = () => {

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    // Get access token from localStorage and decode it
    const accessToken = localStorage.getItem('accessToken');
    const decodedJwt = accessToken && parseJwt(accessToken);
    const expirationDate = decodedJwt && decodedJwt.exp * 1000;

    // If accessToken expire, redirect user to login or else redirect user to the desired page
    return (
      <Route {...rest} render={props => {
        if (!accessToken || !decodedJwt || expirationDate < Date.now()) {

          return <Redirect to={{
            pathname: "/login",
            state: { from: props.location }
          }} />
        }
        return <Component {...props} />
      }
      } />
    );
  };

  const PublicRoute = ({ component: Component, restricted, ...rest }) => {

    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    // Get access token from localStorage and decode it
    const accessToken = localStorage.getItem('accessToken');
    const decodedJwt = accessToken && parseJwt(accessToken);
    const expirationDate = decodedJwt && decodedJwt.exp * 1000;

    return (
      <Route {...rest} render={props => {
        //if user is login and token is not expired, always redirect them to dashboard page
        if (accessToken && decodedJwt && expirationDate > Date.now()) {
          return <Redirect to="/dashboard" />
        }
        return <Component {...props} />

      }} />
    );
  };

  return (
    <AuthProvider>
      <div className="App">
        <Switch>
          <PublicRoute restricted={false} path="/login" component={Login} exact />
          <PublicRoute restricted={false} path="/" component={Home} exact />
          <PublicRoute restricted={false} path="/legalAndDisclosures" component={LegalAndDisclosures} exact />
          <PublicRoute restricted={false} path="/forgotPassword" component={ForgotPassword} exact />
          <PublicRoute restricted={false} path="/privacyPolicy" component={PrivacyPolicy} exact />
          <PublicRoute restricted={false} path="/forgotPassword" component={ForgotPassword} exact />
          <PublicRoute restricted={false} path="/contactUs" component={ContactUs} exact />
          <PublicRoute restricted={false} path="/register" component={Register} exact />
          <PublicRoute path="/twoFactorAuth" component={TwoFactorAuth} exact />
          <PrivateRoute path="/entitymanagement" component={EntityManagement} exact />
          <PrivateRoute path="/emailalert" component={EmailAlert} exact />
          <PrivateRoute path="/dashboard" component={(props) => {
            const { search } = props.location;
            const { planeId, dateFrom, dateTo, watchlistSet } = queryString.parse(search);
            const selectedPlaneID = planeId || '';
            const flightDateFrom = dateFrom || '';
            const flightDateTo = dateTo || '';
            const isWatchlistSet = watchlistSet || '';

            return <Dashboard
              selectedPlaneID={selectedPlaneID}
              flightDateFrom={flightDateFrom}
              flightDateTo={flightDateTo}
              isWatchlistSet={isWatchlistSet}
              {...props}
            />;
          }} exact />
          <PrivateRoute path="/userManagement" component={UserManagement} exact />
          <PrivateRoute path="/changePassword" component={ChangePassword} exact />
        </Switch>
      </div>
    </AuthProvider>
  );
}

export default App;
