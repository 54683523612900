import React from 'react';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Footer from "../footer/footer";
import './home.scss';
import GhraLogo from '../../assets/img/GH-logo-research-advisors.png';

class Home extends React.Component {
  render() {
    return (

      <>
        <div className="main-container boxPage homePage">
          <video id="myVideo" loop muted autoPlay playsInline>
            <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
          </video>
          <Header history={this.props.history} />

          <Grid container >

            <Grid item sm={12} md={12} className="home" >
              <div>
                <img src={GhraLogo} alt="logo" />
                <h3>Jet Tracker</h3> 
                <br />
                <p className="desc">Our proprietary corporate jet data portal enhances investors' mosaic <br />by flagging possible signs of M&amp;A or other strategic changes.</p>
                <p className="desc">  <Link to={'/login'}>CLIENT LOGIN</Link> &nbsp;&nbsp;&nbsp; <Link to={'/register'}>REQUEST TRIAL ACCESS</Link></p>
              </div>
            </Grid>
          </Grid>
          <p className="bottomLink"><a href='https://gordonhaskett.com/research/pc/edx' target='_blank'>GORDON HASKETT EVENT-DRIVEN RESEARCH</a></p>
        </div>
        <Footer
          linkSection="" />
      </>
    );
  }
}

export default Home;