import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import MatDatagrid from 'f1-mat-data-grid-react';
import { post, get, deleteData } from '../../api/api';
import { GET_TICKER, ALERT_EMAIL_POST, ALERT_EMAIL_GET, ALERT_EMAIL_DELETE, UPDATE_EMAIL_ALERT, GET_EMAIL_ALERT_STATUS } from '../../api/baseURL';
import Header from '../header/header';
import Footer from '../footer/footer';
import ModalPopup from 'f1-modal-react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import './emailAlert.scss'

class EmailAlert extends React.Component {
    constructor() {
        super();
        this.state = {
            tickers: [],
            company: "",
            companyObj: [],
            ticker: [],
            modalDeleteShow: false,
            message: "",
            disableEmailAlert: false,
            modalAlertShow: false,
            current: false
        }
    }



    deleteEntity = () => {
        let entityName = this.state.deleteData["EntityName"];
        let ticker = this.state.deleteData["Ticker"] == null ? '' : this.state.deleteData["Ticker"];
        let email = localStorage.getItem("email");
        deleteData(ALERT_EMAIL_DELETE + '/?p_company=' + encodeURIComponent(entityName) + '&p_ticker=' + encodeURIComponent(ticker) + '&p_username=' + email).then(res => {
            this.setState({ message: "Entity Deleted", showMsg: true, modalDeleteShow: false })
            get(ALERT_EMAIL_GET + email).then(response => {
                this.setState({ tickers: response.data })
            })
        })
    }


    onsubmit = (e) => {
        e.preventDefault()
        let email = localStorage.getItem("email");
        let entityName = this.state.company;
        let ticker = this.state.companyObj[0]["ticker"];
        post(ALERT_EMAIL_POST + '/?p_useremail=' + email + '&p_company=' + encodeURIComponent(entityName) + '&p_ticker=' + encodeURIComponent(ticker)).then(res => {
            get(ALERT_EMAIL_GET + email).then(response => {
                this.setState({ tickers: response.data })
            })
        })
        let newRecord = { companyticker: this.state.companyObj[0] }
        this.setState({ tickers: [...this.state.tickers, newRecord] })
        this.setState({ company: "", companyObj: [] })
    }


    onChange = (event, newInputValue) => {
        let data = this.state.ticker.filter(el => el.entityName == newInputValue);
        this.setState({ company: newInputValue, companyObj: data })
    }


    deleteConfirmation = (props) => {
        this.setState({ modalDeleteShow: true, deleteData: props, deleteEmail: props.email })

    }


    disableAlertConfirmation = () => {

        const isCheckBoxChecked = this.state.disableEmailAlert
        let email = localStorage.getItem("email");
        if (isCheckBoxChecked) {

            // If checkbox is already checked, uncheck it directly
            this.setState({ disableEmailAlert: false }, () => {
                post(UPDATE_EMAIL_ALERT, { userEmail: email, emailAlertStatus: 1 }).then(res => {
                    console.log("success updated!")
                })
            });


        } else if (this.state.disableEmailAlert === false) {
            // If checkbox is checked at first, display the confirmation Popup
            this.setState({ modalAlertShow: true })
        }

    }


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showMsg: false })
    };


    onUserClickYes = () => {
        // this.state.current.isAdmin="Yes"

        this.setState({ modalAlertShow: false })
        this.setState({ disableEmailAlert: true })
        let email = localStorage.getItem("email");

        post(UPDATE_EMAIL_ALERT, { userEmail: email, emailAlertStatus: 0 }).then(res => {
            console.log("Successfully disable user's email alert.")
        })

    }


    onUserClickNo = () => {
        this.setState({ disableEmailAlert: false })
        this.setState({ modalAlertShow: false })

    }


    onEnter = () => {

    }
    onExit = (e) => {

    }


    componentDidMount() {
        get(GET_TICKER).then(res => {
            this.setState({ ticker: res.data })
        })
        let email = localStorage.getItem("email");
        get(ALERT_EMAIL_GET + email).then(res => {
            this.setState({ tickers: res.data })
        })
        get(GET_EMAIL_ALERT_STATUS + email).then(res => {
            if (res.data.emailAlertStatus === '1') {
                this.setState({ disableEmailAlert: false })
            } else {
                this.setState({ disableEmailAlert: true })

            }
        })

    }


    render() {

        let top100Films = this.state.ticker;
        return (
            <>
                <div className="main-container emailAlert">
                    <Header history={this.props.history} />
                    <div className="filter-div">
                        <h3 className="pageTitle">Select entity to receive email alerts for</h3>
                        <div className="filterSet">
                            <Autocomplete
                                id="combo-box-demo"
                                freeSolo
                                onMouseDownCapture={(e) => e.stopPropagation()}
                                options={top100Films}
                                onInputChange={(event, newInputValue) => { this.onChange(event, newInputValue) }}
                                inputValue={this.state.company}
                                autoHighlight={true}
                                getOptionLabel={(option) => {
                                    let opt = option.entityName ? option.entityName : ""
                                    return opt
                                }}
                                popoverProps={{
                                    open: false
                                }}
                                style={{ width: "30%", display: "inline-block" }}
                                renderInput={(params) => <TextField {...params} label="Company Name/Ticker" variant="outlined" />}
                            />
                            <div className="btnInclude" style={{ display: "inline-block", textAlign: "left", position: "relative", top: "11px", margin: "0 0 0 10px" }}> <Button variant="contained" color="primary" style={{ height: "53px" }} onClick={this.onsubmit}>
                                Include
                            </Button></div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '20px', width: "100%" }}>

                            <label className="switch" style={{ marginTop: '13px' }}>
                                <input id="emailAlertCheckBox" type="checkbox" checked={this.state.disableEmailAlert} onClick={() => this.disableAlertConfirmation()} />
                                <span className="switch-slider">
                                    <span className="switch-thumb"></span>
                                </span>
                            </label>
                            <label htmlFor='emailAlertCheckBox' style={{ display: 'flex', alignItems: 'center', marginLeft: "5px" }}>Disable email alerts</label>
                        </div>

                        <MatDatagrid

                            rows={this.state.tickers}
                            columns={[
                                { title: 'Company Name', field: 'EntityName', sorting: false },
                                { title: 'Company Ticker', field: 'Ticker', sorting: false },
                                { title: 'Action', field: '', sorting: false, render: (props) => <span className="showLinkEmail" onClick={() => this.deleteConfirmation(props)} className="delete">Delete</span> }
                            ]}
                            search={false}
                            // selection={true}
                            title={"Current search filter criteria"}
                            actionsColumnIndex={-1}
                            editable={{
                                editable: false,
                                showEdit: false,
                                showDelete: false,
                                showAdd: false
                            }}

                        />


                    </div>

                </div>
                <Footer />
                <Snackbar open={this.state.showMsg} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="success" >{this.state.message}</Alert></Snackbar>
                <ModalPopup
                    id={"delteUserPopup"}
                    show={this.state.modalDeleteShow}
                    onHide={() => this.setState({ modalDeleteShow: false })}
                    size={"sm"}                                       // "lg","sm" or "xl"
                    centered={true}                                   // vertically center the Dialog in the window
                    modelHeader={"Delete Entity"}                     // to show model header
                    modelContent={<p>Are you sure you want to delete this notification for Entity &nbsp;<span>{this.state.deleteEmail}</span>?</p>}             // to show model content
                    modelFooter={<><Button onClick={this.deleteEntity}>YES</Button><Button onClick={() => this.setState({ modalDeleteShow: false })}>NO</Button></>}             //// to show model footer
                    backdrop={'static'}                               // true, false or 'static'
                    scrollable={true}                                 //boolean
                    onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
                    onExit={this.onExit}                              //Callback fired right before the Modal transitions out
                    closeButtonShow={true}
                    parentClassName="ModalBackground"
                    headerClassName="ModalHeaderBackground"
                    bodyClassName="ModalBodyBackground"
                    footerClassName="ModalFooterBackground"
                />

                <ModalPopup
                    id={"disableAlertsPopup"}
                    show={this.state.modalAlertShow}
                    onHide={() => this.setState({ modalAlertShow: false })}
                    size={"sm"}                                       // "lg","sm" or "xl"
                    centered={true}                                   // vertically center the Dialog in the window
                    modelHeader={"Disable Email Alert"}                     // to show model header
                    modelContent={<p>Are you sure you want to disable the daily email alert?</p>}             // to show model content
                    modelFooter={<><Button onClick={this.onUserClickYes}>YES</Button><Button onClick={this.onUserClickNo}>NO</Button></>}             //// to show model footer
                    backdrop={'static'}                               // true, false or 'static'
                    scrollable={true}                                 //boolean
                    onEnter={this.onEnter}                            //Callback fired before the Modal transitions in
                    onExit={this.onExit}                              //Callback fired right before the Modal transitions out
                    closeButtonShow={true}
                    parentClassName="ModalBackground"
                    headerClassName="ModalHeaderBackground"
                    bodyClassName="ModalBodyBackground"
                    footerClassName="ModalFooterBackground"
                />
            </>
        )
    }
}

export default EmailAlert;
