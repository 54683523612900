export const BASE_URL_JWT = 'https://jets.gordonhaskett.com';
export const GENERATE_TOKEN = BASE_URL_JWT + '/api/generateToken';
export const REFRESH_TOKEN = BASE_URL_JWT + '/api/token/refresh';

export const SEND_OTP = BASE_URL_JWT + '/api/Auth/SendTwoFactorAuthentication?email=';
export const TWO_FACTOR_AUTH = BASE_URL_JWT + '/api/Auth/ValidateTwoFactorAuthentication?email=';

export const USER_ROLES = BASE_URL_JWT + '/api/User/GetUserRole?email=';
export const USER_ADMIN_APPROVE = BASE_URL_JWT + '/api/User/GetUsersAdminApprove';
export const UPDATE_FORGOT_PASWWORD = BASE_URL_JWT + '/api/User/UpdateForgotPassword';

export const UPDATE_NEW_USER = BASE_URL_JWT + '/api/User/UpdateUserAsync';
export const ALL_USERS = BASE_URL_JWT + '/api/User/ListAllUsers';
export const DELETE_USER = BASE_URL_JWT + '/api/User/DeleteUser';
export const CREATE_REQUEST = BASE_URL_JWT + '/api/User/CreateRequest';
export const CREATE_USER = BASE_URL_JWT + '/api/User/CreateNewUser';
export const CHANGE_PASSWORD = BASE_URL_JWT + '/api/User/ChangePassword';

export const BASE_URL_OWNERSHIP = 'https://jets.gordonhaskett.com';
export const GET_OWNERSHIP = BASE_URL_OWNERSHIP + '/api/ownership?';

export const BASE_URL_NOTIFICATION = 'https://jets.gordonhaskett.com';
export const EMAIL_NOTIFICATION = BASE_URL_NOTIFICATION + '/api/Email/DailyFlightsAlert';
export const CUSTOM_EMAIL_NOTIFICATION = BASE_URL_NOTIFICATION + '/api/Email/CustomNotification';

export const UPDATE_EMAIL_ALERT = BASE_URL_NOTIFICATION + '/api/Alert'
export const GET_EMAIL_ALERT_STATUS = BASE_URL_NOTIFICATION + '/api/Alert?userEmail='

export const BASE_URL_TICKER = 'https://jets.gordonhaskett.com';
export const GET_TICKER = BASE_URL_TICKER + '/api/tickers';

export const DASHBOARD_PAGINATION = 'https://jets.gordonhaskett.com/api/flights?'

export const FLIGHT_DATA = 'https://jets.gordonhaskett.com/api/flights';

export const ENTITY_NAME = '';

//Alert APIS
export const ALERT_EMAIL_BASE_URL = 'https://jets.gordonhaskett.com'
export const ALERT_EMAIL_GET = ALERT_EMAIL_BASE_URL + '/api/notification/username/';
export const ALERT_EMAIL_POST = ALERT_EMAIL_BASE_URL + '/api/notification';
export const ALERT_EMAIL_DELETE = ALERT_EMAIL_BASE_URL + '/api/notification';

export const DESTINATION = 'https://jets.gordonhaskett.com/api/destination'
