import React from "react";
import './login.scss';
import '../../App.css';
import DynamicForm from 'f1-dynamic-form-react';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { post, get } from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Footer from "../footer/footer";
import publicIp from "public-ip";
import { USER_ADMIN_APPROVE, UPDATE_FORGOT_PASWWORD, CUSTOM_EMAIL_NOTIFICATION } from '../../api/baseURL';
import { forgotPassEmailSubject, forgotPassEmailTemplate } from "../emailSchema/emailSchema";
import { encryptData } from "../../util/utils";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      current: { id: 1 },
      message: "",
      severity: "",
      ip: "",
      formData: [
        { "key": "email", "type": "email", "disabled": false, "id": "email", "icon": "user", "label": "Email", "className": "form-control", "validators": [{ "name": "required", "value": true }] },
      ]
    };
  }

  /* Function to generate combination of password */
  generateP = () => {
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
      'abcdefghijklmnopqrstuvwxyz0123456789@#$';
    var nums = '0123456789';
    // Login password must have at least 8 characters and contains at least one number
    // First character of the password is selected from random position of nums
    pass += nums[Math.floor(Math.random() * nums.length)]
    let i;
    // The remaining 7 characters are selected randomly from the str string.
    for (i = 1; i <= 7; i++) {
      var char = Math.floor(Math.random() * str.length);
      pass += str.charAt(char)
    }
    return pass;
  }

  submitForm = (event, value) => {
    publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"]
    }).then(res => {
      this.setState({ ip: res })
      get(USER_ADMIN_APPROVE).then(response => {
        // Check if the email inputted by user matches any email of the approved users
        let userEmail = response.data.filter(el => el.Email == event.email)
        let userSchema = userEmail[0]
        if (userEmail.length == 1) {
          // Email matches an approved user
          const password = this.generateP()
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          userSchema.Password = encryptData(password, secretKey)
          userSchema.IPAddress = this.state.ip
          // Update the forgot password in mySQL database
          post(UPDATE_FORGOT_PASWWORD, userSchema).then(res => {
            let emailSchema = {
              "listEmailReceivers": [
                {
                  "receiverName": userSchema.FirstName,
                  "receiverEmail": userSchema.Email
                }
              ],
              "emailSubject": forgotPassEmailSubject,
              "emailBodyVariables": [
                {
                  "firstName": userSchema.FirstName,
                  "Telephone": userSchema.Phone,
                  "Password": password
                }],
              "emailTemplate": forgotPassEmailTemplate
            }

            // Send the email with the recovery password to the user if update password is successful
            post(CUSTOM_EMAIL_NOTIFICATION, emailSchema).then(res => {
              this.setState({ severity: "success", message: "Password recovery has been sent to the entered email!", show: true });
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2000);
            })
              .catch(err => {
                console.log('Error for sending custom email during forgot password is ', err)
              })
          })
        }
        else {
          // Email not matching any approved user
          this.setState({ severity: "error", message: "Email not registered.", show: true })
        }
      }).catch(err => {
        console.log("Error when getting all approved users is", err)
      })
    })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false })
  };
  render() {
    return (
      <>

        <div className="main-container loginPage forgetPASS">
          <video id="myVideo" loop muted autoPlay playsInline>
            <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
          </video>
          <Header history={this.props.history} />
          <Grid container >

            <Grid item sm={4} md={4} className="loginBox" >

              <Paper className={""} style={{ textAlign: "left" }}>
                <span className="cross"><Link to="/"><CloseIcon /></Link></span>
                <DynamicForm
                  key={this.state.current.id}
                  className="form horizontal"
                  title="Forgot Password?"
                  buttonSubmit="Request Password Reset"
                  model={this.state.formData}
                  onSubmit={this.submitForm}
                  disableSubmitOnInValid={false}
                  customValidators={this.customValidators}
                  displayInformation={true}
                  displaydetails=""
                  design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
                  materialOutline={true}
                  extErrors={this.state.extErrors}
                />
              </Paper>
              {/* <p className="copyRight">Copyright © 2021 | All Rights Reserved. </p> */}
            </Grid>


          </Grid>
          <Snackbar open={this.state.show} autoHideDuration={1000} onClose={() => this.handleClose({ vertical: 'Top', horizontal: 'top' })}><Alert severity={this.state.severity} >{this.state.message}</Alert></Snackbar>

        </div>
        <Footer linkSection="" />
      </>
    );
  }
}

export default ForgotPassword;
