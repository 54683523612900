// Saved the userData as user object in localStorage
export const setUserData = (user) => {
    if (user?.constructor.name !== 'Object') {
        throw new Error('No valid data found');
    }
    if (Object.keys(user).length === 0) {
        throw new Error('No data found');
    }
    if (typeof Storage === 'undefined') {
        throw new Error('No valid storage type found');
    }
    // Save user properties separately in localStorage
    localStorage.setItem('email', user.email);
    localStorage.setItem('accessToken', user.accessToken);
    localStorage.setItem('refreshToken', user.refreshToken);
};

// Remove user object and role object from localStorage
export const clearUserData = () => {
    if (typeof Storage === 'undefined') return;
    localStorage.clear();
}

// Retrieve refresh token from user object in localStorage
export const getRefreshToken = () => {
    if (typeof Storage === 'undefined') {
        return false;
    }
    return localStorage.getItem("refreshToken");
};

// Update access token from user object in localStorage
export const updateAccessToken = (token) => {
    if (typeof Storage === 'undefined') return;
    localStorage.setItem('accessToken', token);
};
