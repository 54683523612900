import React from "react";
import './login.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Footer from "../footer/footer";
// Import API from backend server
import { GENERATE_TOKEN, USER_ROLES, SEND_OTP } from '../../api/baseURL';
import { post, get } from '../../api/api';
import publicIp from "public-ip";
// Import global variable from authContext
import AuthContext from '../../routes/authContext';
import LoadingOverlay from 'react-loading-overlay';
import Cookies from 'js-cookie';
import { encryptData, decryptData } from "../../util/utils";

class Login extends React.Component {

  constructor() {
    super();

    this.state = {
      ip: "",
      formValues: {
        email: '',
        password: ''
      },
      formErrors: {
        email: '',
        password: ''
      },
      formValidity: {
        email: false,
        password: false
      },
      rememberMe: false,
      errorMessage: "",
      show: false,
      loading: false
    };
    this.isComponentMounted = React.createRef();

  }

  handleSubmit = (event, data) => {
    event.preventDefault();
    // Trigger loading icon when data is submitted to server
    this.setState({ loading: true })

    // Get the submitted data from login form
    const email = event.target.elements.email.value
    const password = event.target.elements.password.value
    const { ip } = this.state;

    // Encrypt password
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const encryptedPassword = encryptData(password, secretKey);

    // Send encrypted password, email and ip to backend server for verification
    post(GENERATE_TOKEN,
      {
        "email": email,
        "password": encryptedPassword,
        "ip": ip
      }
    ).then(res => {
      // Post request to generate token sent successfully (user is authenticated)
      if (res.status === 200) {

        // Response received = json object with two properties:
        // userData: an object with properties "email", "accessToken" and "refreshToken" 
        // OTPStatus: an object with property 'isVerifyOTP' 

        const userData = res.data.userData;

        if (this.state.rememberMe) {
          // if rememberMe is selected
          Cookies.set("email", email, { expires: 30 });
          Cookies.set("password", encryptedPassword, { expires: 30 });
        } else {
          // if rememberMe is not selected
          Cookies.remove("email");
          Cookies.remove("password");
        }

        // If the user login with new IP address, new OTP is to generated for verification
        // If isVerifyOTP is true
        if (!res.data.OTPStatus.isVerifyOTP) {

          // if isVerifyOTP is false, get new OTP from backend server
          get(SEND_OTP + this.state.formValues.email + '&ip=' + ip)
            .then((response) => {
              if (response.status === 200) {
                alert("One-Time Passcode sent");
                if (this.props.location.state !== undefined) {
                  const { search, pathname } = this.props.location.state.from
                  this.props.history.push({ pathname: "/twoFactorAuth", state: { from: '/', search, pathname, userData, ip } });
                } else {
                  // Direct user along with their userData and ip to twoFactorAuth page to input new OTP received for verification
                  this.props.history.push({ pathname: "/twoFactorAuth", state: { userData, ip } })
                }
                setTimeout(() => {
                  if (this.isComponentMounted.current) {
                    this.setState({ loading: false });
                  }
                }, 2000); //  Simulating API call
              }
            })
            .catch(error => {
              // If error occur when generating new OTP in backend server
              if (error) {
                alert("Oops, this site can't be reached!");

                setTimeout(() => {
                  if (this.isComponentMounted.current) {
                    this.setState({ loading: false })
                  };
                }, 2000); // Simulating API call
                return Promise.reject();

              } else {
                alert("Oops, this site can't be reached!");
              }

            });
        }

        // if isVerifyOTP is true (user login with previous IP address)
        else {
          // Navigate to dashboard and save userData in localstorage
          this.context.onLogin(userData)
          if (this.props.location.state !== undefined) {
            const { search, pathname } = this.props.location.state.from
            this.props.history.push({ pathname: pathname, search: search, state: { from: '/' } });
          } else {
            this.props.history.push({ pathname: "/dashboard" })
          }
          setTimeout(() => {
            if (this.isComponentMounted.current) {
              this.setState({ loading: false })
            };
          }, 2000); // Simulating API call)

        }
        // Check user roles (admin/common)
        get(USER_ROLES + this.state.formValues.email
        ).then(response => {
          localStorage.setItem("role", response.data.role);
        }).catch(error => {
          console.log("Error on checking user roles is ", error)
        })
      }
    }).catch(// user is not authenticated)
      error => {
        if (error) {
          if (error.status === 400) {
            // Show errorMessage and clear inputed value for email and password on login form
            this.setState({ show: true, errorMessage: "Invalid username and/or password.", email: "", loading: false, formValues: { ...this.state.formValues, email: "", password: "" } })
          }
          else if (error.status === 402) {
            // Show errorMessage and clear inputed value for email and password on login form
            this.setState({ show: true, errorMessage: "Please request for approval from admin before proceeding.", email: "", loading: false, formValues: { ...this.state.formValues, email: "", password: "" } })
          }
          else {// Server error
            this.setState({ show: true, errorMessage: "Oops, this site can't be reached!", loading: false })
          }
        }
        else {
          this.setState({ show: true, errorMessage: "Oops, this site can't be reached!", loading: false })
        }
      })
  };

  // Validation for email and password when user input values on login form
  handleValidation = (target) => {
    const { name, value } = target;
    const fieldValidationErrors = this.state.formErrors;
    const validity = this.state.formValidity;

    // Check input type
    const isEmail = name === "email"
    const isPassword = name === "password";

    // Check input format
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordTest = /\d/;

    // Check input length must not be empty
    validity[name] = value.length > 0;

    fieldValidationErrors[name] = validity[name]
      ? ""
      : `${name} is required and cannot be empty`;
    if (validity[name]) {
      if (isEmail) {
        validity[name] = emailTest.test(value);
        fieldValidationErrors[name] = validity[name] ? "" : `Please enter a valid ${name}`;
      }
      if (isPassword) {
        validity[name] = value.length >= 8 && passwordTest.test(value);
        fieldValidationErrors[name] = validity[name] ? "" : `${name} must be at least 8 characters long and contain at least one number`;
      }
      this.setState({
        formErrors: fieldValidationErrors,
        formValidity: validity
      })

    }
  };

  // Function to check if checkbox is selected
  handleCheckboxChange = (e) => {

    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox
        ? e.target.checked
        : e.target.value
    });

    if (!e.target.checked) {
      Cookies.remove("email");
      Cookies.remove("password");
    }
  }

  // Function to trigger input change on form
  handleChange = ({ target }) => {
    const { formValues } = this.state;
    formValues[target.name] = target.value;
    this.setState({ formValues });
    this.handleValidation(target)
  }


  // Function to direct user to forgotPassword page
  forgotPass = (value) => {
    this.props.history.push('/forgotPassword')
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false })
  };

  checkRememberMe = () => {

    const email = Cookies.get('email');
    const encryptedPassword = Cookies.get("password");
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!this.state.formValues.email && email && encryptedPassword) {
      // if rememberMe is true 
      this.setState({ formValues: { ...this.state.formValues, email: email, password: decryptData(encryptedPassword, secretKey) }, rememberMe: true })
      this.handleValidation({ name: 'email', value: email });
      this.handleValidation({ name: 'password', value: decryptData(encryptedPassword, secretKey) })
    }
  };

  componentDidMount() {
    this.isComponentMounted.current = true;
    publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"]

    }).then(res => {//get user ip address 
      this.setState({ ip: res })
    })
    this.checkRememberMe();
  };

  componentWillUnmount() {
    this.isComponentMounted.current = false;
  };

  render() {
    const { formValidity, formErrors, rememberMe, formValues, show, errorMessage } = this.state;

    return (
      <>
        <div className="main-container loginPage">

          <video id="myVideo" loop muted autoPlay playsInline>
            <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
          </video>

          <Header history={this.props.history} />

          <Grid container >

            <Grid item sm={4} md={4} className="loginBox" >
              <Snackbar open={show} autoHideDuration={3000} onClose={() => this.handleClose({ vertical: 'bottom', horizontal: 'right' })}><Alert severity="error" >{errorMessage}</Alert></Snackbar>

              <Paper className={""} style={{ textAlign: "left" }}>
                <span className="cross"><Link to="/"><CloseIcon /></Link></span>


                <div data-test="container" className="container">
                  <div data-test="row" className="row">
                    <div data-test="col" className="col">

                      <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        classNamePrefix='myLoader_'>
                        <form onSubmit={this.handleSubmit}>

                          <h3 className="form-title">Login</h3>
                          <p className="text-left h5 mb-4"> </p>

                          <div className="form-display-info"></div>
                          <div className="general-error text-danger"></div>

                          <div className="grey-text">
                            <div className="md-form md-outline">
                              <label htmlFor='Email'>Email</label>
                              <input

                                className={`form-control ${formErrors.email ? "is-invalid" : ""
                                  }`}

                                type='text'
                                id="email"
                                name='email'
                                autoComplete="off"
                                onChange={this.handleChange}
                                value={formValues.email}
                                placeholder={"Enter your email"}
                                required
                              />
                              <div className="invalid-feedback">{formErrors.email}</div>
                            </div>
                            <div className="md-form md-outline">
                              <label htmlFor='password' >Password</label>
                              <input

                                className={`form-control ${formErrors.password ? "is-invalid" : ""
                                  }`}
                                type='password'
                                id='password'
                                name='password'
                                value={formValues.password}
                                onChange={this.handleChange}
                                placeholder={"Enter your password"}
                                required
                              />
                              <div className="invalid-feedback">{formErrors.password}</div>
                            </div>

                            <div className="form-check">

                              <input
                                className="form-check-input"
                                type='checkbox'
                                id='rememberMe'
                                name='rememberMe'
                                onChange={this.handleCheckboxChange}
                                checked={rememberMe}

                              />
                              <label htmlFor='RememberMe' className="grey-text">Remember Me</label>
                            </div>
                          </div>

                          <button data-test="button" disabled={!formValidity.email || !formValidity.password} className={'btn-default btn Ripple-parent btn btn-primary'}>Submit</button>



                          <a href>
                            <p className="new-user">
                              <div style={{ textAlign: "center", padding: "5px", md: "12" }}>
                                <Link to="/register">NEW USER? REQUEST ACCESS HERE!</Link><br />
                                <Link to="/forgotPassword">FORGOT PASSWORD?</Link>
                              </div>
                            </p>
                          </a>





                        </form>
                      </LoadingOverlay>

                    </div>
                  </div>
                </div>
              </Paper>
              {/* <p className="copyRight">Copyright © 2021 | All Rights Reserved. </p> */}
            </Grid>


          </Grid>

        </div>
        <Footer linkSection="" />
      </>
    );
  }
}
Login.contextType = AuthContext

export default Login;
