import React from 'react';

import '../../App.css';
import DynamicForm from 'f1-dynamic-form-react';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close'
import {post,get} from '../../api/api';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Footer from "../footer/footer";
import {GENERATE_TOKEN} from '../../api/baseURL';
import './legalAndDisclosures.scss'
// import SliderImage1 from '../../assets/estimation_slider1.jpg'
// import SliderImage2 from '../../assets/estimation_slider2.jpg'
// import SliderImage3 from '../../assets/estimation_slider3.jpg'

import Signin from "f1-signin-form-react";


class LegalAndDisclosures extends React.Component {
  
  
  render() {
    return (
      <>
      <div className="main-container boxPage legalPage">
      <video id="myVideo" loop muted autoPlay playsInline>
 <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
</video>
        <Header history={this.props.history}/>
          
        <Grid container >
       
          <Grid item sm={12} md={12} className="legalAndDisclosures" >
        <div className="inner-div">
        <span className="cross"><Link to="/"><CloseIcon/></Link></span>
            <h3>Terms Of Use</h3>
            <br/>
            <b>Terms and Conditions of Use</b>
            <p>These Terms of Use (“Terms”) describe the terms and conditions on which Gordon Haskett Research Advisors (“GHRA” or “we” or “us”) offers you access to its GHRA Jets Website (“Website”). Read these Terms carefully because they constitute a legal agreement between GHRA and you. By accessing and using this Website, you agree to be bound by these Terms. If you do not agree to any of these Terms, please do not use the Website. The Company may update the Website at any time, or update, modify, or delete certain features and functionality. </p>
            <br/>
            <b>Registration.</b>
            <p>Subscriber is expressly prohibited from sharing his/her account login information with anyone. You are solely responsible for the confidentiality and use of your user ID and password, as well as for any use, misuse. You agree to notify GHRA immediately if you become aware of any loss, theft or unauthorized use of your user ID or password. </p>
            <br/>
            <b>Intellectual Property.</b>
            <p>Subscriber expressly acknowledges GHRA’s representation that the Software is confidential and proprietary to GHRA and/or its third party data suppliers and constitutes or incorporates trade secrets of GHRA and/or its third party data suppliers. Subscriber shall receive and maintain the content as a confidential disclosure and shall not disclose the content to any other person or entity except employees of Subscriber or as permitted by GHRA hereinafter for the purpose of evaluating the Data. Notwithstanding the foregoing, the obligation to maintain confidentiality of the content shall not extend to:</p>
            <br/>
            <p>i.	any content that is in the public domain other than as a result of Subscriber’s breach of confidentiality or the wrongful conduct of others; or</p>
            <br/>
            <p>ii.	any content which Subscriber is required to provide or disclose to any court, government or regulatory body of competent jurisdiction; or</p>
            <br/>
            <p>iii.	any content that Subscriber is required to provide or disclose under any law, statute, regulation, directive, decree, order or notification binding on Subscriber.</p>
            <b>Trademarks.</b>
            <p>The trademarks, service marks, and logos of GHRA (“Trademarks”) used and displayed on the Website, the Service and in the Content, are registered and unregistered trademarks or service marks of GHRA, and other third parties. Nothing on the Website, Service or the Content should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Trademarks, without our prior written permission specific for each such use. None of the Content may be retransmitted without our express, written consent for each and every instance.</p>
            <b>Compliance with Applicable Laws.</b>
            <br/>
            <p>The Website and the Service is based in the United States. We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States. If you access the Website or the Service from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.</p>
            <b>Limitation of Damages.</b> 
            <br/>
            <p>Except for breach of confidentiality, misuse or misappropriation of GHRA’s intellectual property, or in the event of a party’s fraud, willful misconduct or gross negligence, neither party shall be liable to the other or to any third party for indirect or consequential damages, whether in contract, tort or otherwise, even if a party has been advised of the possibility of such damages.</p>
            <b>Warranty Disclaimer. </b> 
            <p>Except for breach of confidentiality, misuse or misappropriation of GHRA’s intellectual property, or in the event of a party’s fraud, willful misconduct or gross negligence, neither party shall be liable to the other or to any third party for indirect or consequential damages, whether in contract, tort or otherwise, even if a party has been advised of the possibility of such damages.</p>
            <b>Miscellaneous</b> 
            <p>This Agreement is governed by the internal substantive laws of the State of New York, without respect to its conflict of laws provisions. You expressly agree to submit to the exclusive personal jurisdiction of the state and federal courts sitting in the State of New York. </p>
            <p>You agree that any cause of action arising out of or related to the Website or the content must be commenced by you within one-year after the cause of action accrues, otherwise such cause of action is permanently barred.</p>
            <p>You agree that any cause of action arising out of or related to the Website or the content must be commenced by you within one-year after the cause of action accrues, otherwise such cause of action is permanently barred.</p>
            <p>If any provision of this Agreement is found to be invalid by any court having competent jurisdiction or terminated in accordance with the termination provision above, the invalidity or termination of such provision shall not affect the validity of the any other provisions of this Agreement. </p> 
            <p>Our failure to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement.  </p>  
            <p>The section headings are provided merely for convenience and shall not be given any legal import. This Agreement will inure to the benefit of our successors, assigns, licensees, and sublicensees. </p>  
            <b>Termination.</b> 
            <p>We reserve the right, in our sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Service, at any time and for any reason without prior notice or liability. We reserve the right to change, suspend, or discontinue all or any part of the Service at any time without prior notice or liability. </p>   
              </div>         
           
          </Grid>
         
        </Grid>
     
      </div>
         <Footer linkSection=""/>
      </>
    );
  }
}

export default LegalAndDisclosures;
