import { Grid } from '@material-ui/core';
import React from 'react';
import { post } from '../../api/api';
import { CHANGE_PASSWORD } from '../../api/baseURL';
import './changePass.scss';
import Paper from '@material-ui/core/Paper';
import Header from '../header/header';
import DynamicForm from 'f1-dynamic-form-react';
import Footer from '../footer/footer'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { encryptData } from "../../util/utils";

class ChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            current: { id: 1 },
            showMsg: false,
            message: "",
            severity: "",
            formData: [
                // Modified validator.js in f1-dynamic-form-react in node_modules to include isPassword validator
                { "key": "oldPassword", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Old Password", "validators": [{ "name": "required", "value": true }, { "name": "isPassword", "value": true }] },
                { "key": "newPassword", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "New Password", "validators": [{ "name": "required", "value": true }, { "name": "isPassword", "value": true }] },
                { "key": "confirmationPassword", "type": "password", "disabled": false, "id": "password", "icon": "user", "label": "Confirm Password", "validators": [{ "name": "required", "value": true }, { "name": "isPassword", "value": true }] },
            ]
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showMsg: false })
    };

    submitForm = (event, value) => {

        const secretkey = process.env.REACT_APP_SECRET_KEY;

        if (event.confirmationPassword !== event.newPassword) {
            this.setState({ message: "Confirmation Password and New Password do not match", showMsg: true })
        } else if (event.confirmationPassword === event.newPassword) {
            if (event.confirmationPassword && event.newPassword) {
                const encryptedNewPassword = encryptData(event.newPassword, secretkey);
                const encryptedOldPassword = encryptData(event.oldPassword, secretkey);
                let requestSchema = {
                    "email": localStorage.getItem("email"),
                    "oldPassword": encryptedOldPassword,
                    "newPassword": encryptedNewPassword
                }
                post(CHANGE_PASSWORD, requestSchema).then(res => {
                    this.setState({ message: "Password changed successfully", showMsg: true, severity: 'success' })
                    localStorage.clear(); // Remove user item that contains previous authentication state
                    setTimeout(() => {
                        this.props.history.push("/login")
                    }, 3000)
                }).catch(err => {
                    if (err) {
                        if (err.status === 403) {
                            this.setState({ message: "Old password is not valid!", showMsg: true, severity: 'error' })
                        }
                        else {
                            this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: 'error' })
                        }
                    } else {
                        this.setState({ message: "Oops, this site can't be reached.", showMsg: true, severity: 'error' })
                    }
                })
            }
        }
    }

    render() {
        return (<>
            <div className="main-container-inner changePassPage">
                <Header history={this.props.history} />
                <div className="MuiPaper-root">
                    <Grid item sm={6} md={6} >
                        <Paper style={{ textAlign: "left" }}>
                            <DynamicForm
                                key={this.state.current.id}
                                className="form horizontal"
                                title="Change Password"
                                buttonSubmit="Submit"
                                // defaultValues={this.state.current}
                                model={this.state.formData}
                                onSubmit={this.submitForm}
                                disableSubmitOnInValid={false}
                                customValidators={this.customValidators}
                                displayInformation={true}

                                design="bootstrap" // material/bootrstap/advanceMaterial/advanceBootstrap
                                materialOutline={true}
                                extErrors={this.state.extErrors}
                                onChange={this.onChangeHandler}
                            />
                            {/* <p style={{textAlign:"center",padding:"5px",fontSize:"15px"}}><Link to="/login">LOGIN HERE!</Link></p> */}



                        </Paper>
                    </Grid>
                    <Snackbar open={this.state.showMsg} autoHideDuration={5000} onClose={() => this.handleClose({ vertical: 'top', horizontal: 'center' })}><Alert severity={this.state.severity} >{this.state.message}</Alert></Snackbar>
                </div>
            </div>
            <Footer />
        </>
        )
    }
}
export default ChangePassword;
